<template>
  <footer 
    class="navigation-child-footer"
    :style="{
      paddingTop: height+'px',
    }"
  >
    <div 
      v-show="showContent"
      ref="content"
      class="navigation-child-footer__content"
    >
      <FavouriteTogglerRow 
        :menu_id="menu_id"
      />
    </div>
  </footer>
</template>

<script>
import { mapGetters } from "vuex";
import FavouriteTogglerRow from "@/components/reusable/FavouriteTogglerRow";

export default {
  name: "NavigationChildFooterView",
  components: {
    FavouriteTogglerRow,
  },
  props: [
    'menu_id',
  ],
  data(){
    return {
      height: 0,
    }
  },
  computed: {
    ...mapGetters("mainPage", [
      'get_menu_item_depth',
    ]),
    showContent(){
      return this.get_menu_item_depth(this.menu_id) > 2;
    },
  },
  watch: {
    showContent: {
      immediate: true,
      handler(val){
        this.$nextTick(() => {
          this.height = val ? this.$refs.content.offsetHeight : 0;
        });
      },
    }
  }
}
</script>
<style lang="scss">
.navigation-child-footer{
  &__content{
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.02), 0px -2px 12px rgba(236, 236, 236, 0.08);
    padding: 16px 14px;
    @supports(padding: max(0px)) {      // check if ios device 
      padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
    }
  }
}
</style>