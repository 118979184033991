<template>
  <div 
    class="chart-line-form"
    :class="{
      'chart-line-form--rotated': rotated,
    }"
    :style="{
      height: width && width + 'px',
      width: height && height + 'px',
    }"
  >
    <apexchart 
      v-if="!rotated || (width && height)"
      :width="height"
      :height="width"
      :offsetx="24"
      type="line"
      :series="seriesFromContent"
      :options="chartOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'ChartLineForm',
  props: ['component', 'rotated'],
  data(){
    return {
      height: undefined,
      width: undefined,
      // series: this.component?.chart?.series,
      // default options
      options: {
        // Hide default toolbar
        chart: {
          // parentHeightOffset: 32,
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              pan: false,
              reset: false,
              customIcons: [{
                icon: `<img src="${require('@/assets/chart_zoom.png')}" width="20" height="20"/>`,
                index: 0,
                title: this.$translate('form-chart_increase'),
                class: 'chart-line__zoom-icon',
                click: this.onRotateClick.bind(this)
              }]
            }
          },
        },
        legend: {
          // https://apexcharts.com/docs/options/legend/
          // floating: true,
          offsetY: 16,
          itemMargin: {
            vertical: 16,
          }
        },
        // offsetY: 24,
        // Disable style change on bar click or hover
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
          active: {
            filter: {
              type: 'none',
            }
          },
        },
        // hide tooltip when user hovers or clicks over chart area.
        stroke: {
          width: 1,
          // dashArray: [0, 4],
        },
        tooltip: {
          enabled: false,
        },
      }
    }
  },
  computed: {
    seriesFromContent(){
      let seriesdata = this.component?.content?.map(col => {
        return {
          color: col?.color,
          data: col.content.map((el) => el.value),
          name: col.line_title,
        }
      });

      return seriesdata
    },
    chartOptions(){

      let xaxis = {
        title: {
          text: this.component?.options?.x_coordinate_text,
          style: {
            fontSize: '14px',
            fontFamily: 'TTCommons',
            fontWeight: 400,
          }
        },
        // беру титулы из первого эллемента
        categories: this.component?.content?.[0]?.content?.map(col => col.title),
        axisBorder: {
          show: false,
          // color: "#DDE1E5",
          // offsetX: 8,
          // offsetY: 8,
        },
        axisTicks: {
          show: false,
        },
      };

      let yaxis = {
        title: {
          text: this.component?.options?.y_coordinate_text,
          style: {
            fontSize: '14px',
            fontFamily: 'TTCommons',
            fontWeight: 400,
          },
        }
      }

      return {
        ...this.options,
        xaxis,
        yaxis,
      }
    }
  },
  mounted(){
    if (this.rotated){
      this.height = this.$el.scrollHeight;
      this.width = this.$el.scrollWidth;
    }
  },
  methods: {
    onRotateClick(){
      if (this.rotated){
        this.$store.dispatch('layout/clearRotatedChartComponent');
      } else {
        this.$store.dispatch('layout/setRotatedChartComponent', this.component);
      }
    }
  },
}
</script>
<style lang="scss">
.chart-line-form{
  background: #F6F6F6;
  border-radius: 12px;
  margin: 0 16px;
  &--rotated{
    transform-origin: center;
    position: relative;
    height: 100%;
    margin: 0;
    // height: 100vw;
    // width: 100vh;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .apexcharts-toolbar{
    z-index: 1;
  }
  .chart-line__zoom-icon{
    margin: 4px 0 0 0;
  }
}
</style>