<template>
  <div class="FORMS__progress__circle">
    <svg width="69" height="68" viewBox="0 0 69 68" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:ref="'svg'" v-bind:class="'FORMS__progress__svg'" :style="{
        color: color,
      }" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M34 2a32 32 0 110 64 32 32 0 010-64h0z" stroke="#DDE1E5" stroke-width="4" stroke-linecap="round"/><path class="value-path" d="M34 2a32 32 0 110 64 32 32 0 010-64h0z" stroke="currentColor" stroke-width="4" stroke-linecap="round"/></svg>
    <p class="large-medium">{{ value + '%'}}</p>
  </div>
</template>

<script>
export default {
  name: 'ProgressCircular',
  props: {
    color: {
      type: String,
      default: '#0086E7',
    },
    value: {
      type: Number,
      default: 0,
    }
  },
  mounted(){
    let path = this.$refs.svg.getElementsByClassName('value-path')[0];
    let totalLength = path.getTotalLength();
    let adjustLength = (totalLength * this.value) / 100;
    console.log(path.getTotalLength());
    console.log(path.pathLength);
    path.style.setProperty('stroke-dasharray', totalLength);
    path.style.setProperty('stroke-dashoffset', totalLength);
    
    this.$nextTick(() => {
      path.style.setProperty('transition', 'stroke-dashoffset 1s ease-out');
      path.style.setProperty('stroke-dashoffset', totalLength - adjustLength);
    
    })
  }
}
</script>
<style lang="scss">
.FORMS__progress__circle{
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  .FORMS__progress__svg{
    position: absolute;
    // .value-path {
      // stroke-dasharray: 1;
      // stroke-dashoffset: 1;
      // // animation: dash 3s linear forwards;
      // transition: stroke-dashoffset 1s linear;
    // }
  }
}
// @keyframes dash {
//   to {
//     stroke-dashoffset: 0;
//   }
// }
</style>