<template>
  <div class="image-form mb-16">
    <v-img 
      :src="url" 
      :height="height"
      :width="width"
      :alt="loadErr"
      @error="handleImgError"
    />
  </div>
</template>

<script>
export default {
  name: 'ImageForm',
  props: ['component'],
  data(){
    return {
      url: this.component.url,
      height: this.component.height,
      width: this.component.width,
      loadErr: undefined,
    }
  },
  methods: {
    handleImgError(err){
      this.loadErr = err?.message || err;
    }
  }
}
</script>
<style>
.image-form{
  width: 100%;
  height: auto;
  text-align: center;
}
</style>