<template>
  <div 
    class="switch-form"
    @click="switchValue = !switchValue"
  >
    <div 
      class="switch-form-toggler"
      :class="{
        'switch-form-toggler--active': switchValue,
      }"
    >
      <div class="switch-form-toggler__circle-container">
        <div class="switch-form-toggler__circle"></div>
      </div>
    </div>
    <p 
      :style="{
        color: text_color,
      }" 
      class="ml-8"
      :class="text_size || 'small'"
    >{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'SwitchForm',
  props: ['component', 'error_msg'],
  data(){
    return {
      switchValue: this.component.default_state || false,
      title: this.component?.title,
      text_color: this.component?.options?.text_color,
      text_size: this.component?.options?.text_size,
    }
  },
  watch: {
    switchValue: {
      handler(newValue){
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: newValue
        });
      },
      immediate: true,
    }
  }
}
</script>

<style lang="scss">
.switch-form{
  display: flex;
  padding: 8px 16px;
  user-select: none;
  .switch-form-toggler{
    flex-shrink: 0;
    background: #8897A5;
    border-radius: 12px;
    margin-right: 12px; 
    height: 20px;
    padding: 2px;
    width: 32px;
    // position: relative;
    
    .switch-form-toggler__circle-container{
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      transition: width 0.1s ease-in;
      .switch-form-toggler__circle{
        background: #FFFFFF;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        
        // transition-property: left, right;
        // transition-duration: 3s, 5s;
        
      }
    }
    &.switch-form-toggler--active{
      background: #0086E7;
      .switch-form-toggler__circle-container{
        width: 16px;
      }
    }
  }
}

</style>