<template>
  <div 
    class="chart-bar-form"
    :class="{
      'chart-bar-form--rotated': rotated,
    }"
    :style="{
      height: width && width + 'px',
      width: height && height + 'px',
    }"
  >
    <apexchart 
      v-if="!rotated || (width && height)"
      :width="height"
      :height="width"
      type="bar"
      :series="series || seriesFromContent"
      :options="chartOptions"
    />
  </div>
</template>

<script>
export default {
  name: 'ChartBarForm',
  props: ['component', 'rotated'],
  data(){
    return {
      height: undefined,
      width: undefined,
      series: this.component?.chart?.series,
      options: {
        chart: {
          // TODO parentHeightOffset fixes bottom overflow hidden glitch
          // find other solution later (never)
          // parentHeightOffset: 32,
          // Hide default toolbar
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: true,
            tools: {
              download: false,
              selection: false,
              pan: false,
              reset: false,
              customIcons: [{
                icon: `<img src="${require('@/assets/chart_zoom.png')}" width="20" height="20"/>`,
                index: 0,
                title: this.$translate('form-chart_increase'),
                class: 'chart-bar__zoom-icon',
                click: this.onRotateClick.bind(this)
              }]
            }
          },
        },
        legend: {
          show: false,
          margin: {
            vertical: 16,
          }
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            distributed: true,
            dataLabels: {
              position: 'bottom', // top, center, bottom
            },
          }
        },
        // Disable style change on bar click or hover
        states: {
          hover: {
            filter: {
              type: 'none',
            }
          },
          active: {
            filter: {
              type: 'none',
            }
          },
        },
        // hide tooltip when user hovers or clicks over chart area.
        tooltip: {
          enabled: false,
        }
      }
    }
  },
  computed: {
    seriesFromContent(){
      // if (this.component?.type === 'columns'){
      let data =  this.component?.content?.map(col => col.value);
      return [{
          name: '',
          data,
      }]
      // } else {
      //   return []
      // }
    },
    chartOptions(){
      let xaxis = {
        categories: this.component?.content?.map(col => col.title),
        axisBorder: {
          show: false,
          // color: "#DDE1E5",
          // offsetX: 8,
          // offsetY: 8,
        },
        axisTicks: {
          show: false,
        },
        title: {
          text: this.component?.options?.x_coordinate_text,
          style: {
            fontSize: '14px',
            fontFamily: 'TTCommons',
            fontWeight: 400,
          },
        },
      };
      let colors = this.component?.content?.map(col => col.color);
      let yaxis = {
        title: {
          text: this.component?.options?.y_coordinate_text,
          style: {
            fontSize: '14px',
            fontFamily: 'TTCommons',
            fontWeight: 400,
          },
        }
      }
      return {
        ...this.options,
        ...(xaxis.categories && { xaxis }  || undefined),
        yaxis,
        colors,
        // dataLabels,
      }
    }
  },
  mounted(){
    if (this.rotated){
      this.height = this.$el.scrollHeight;
      this.width = this.$el.scrollWidth;
    }
  },
  methods: {
    onRotateClick(){
      if (this.rotated){
        this.$store.dispatch('layout/clearRotatedChartComponent');
      } else {
        this.$store.dispatch('layout/setRotatedChartComponent', this.component);
      }
    }
  },
}
</script>
<style lang="scss">
.chart-bar-form{
  background: #F6F6F6;
  border-radius: 12px;
  margin: 0 16px;
  &--rotated{
    transform-origin: center;
    position: relative;
    height: 100%;
    margin: 0;
    // height: 100vw;
    // width: 100vh;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(90deg);
  }
  .apexcharts-toolbar{
    z-index: 1;
  }
  .chart-bar__zoom-icon{
    margin: 4px 0 0 0;
  }
}
</style>