<template>
  <div 
    class="push-checkbox-form"
    @click.capture="switchValue"
  >
    <v-simple-checkbox
      ref="checkbox"
      :value="status === 'enabled'"
      :disabled="isLoading"
      color="#267CCC"
      :ripple="false"
    ></v-simple-checkbox> 
    <p class="ml-12">
      {{ title }}
    </p> 
    <p 
      v-if="error_msg" 
      class="error-msg"
    >{{error_msg}}</p>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: 'PushCheckboxForm',
  props: ['component', 'error_msg', 'isLoading'],
  data(){
    return {
      title: this.component?.title
    }
  },
  computed: {
    ...mapState('notifications', ['status']),
  },

  methods: {
    async switchValue(){
      try {
        await this.$store.dispatch('notifications/switchStatus');
        console.log('notifications status', this.status);
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: this.status,
        });
      } catch(err){
        console.log(err);
      }
    }
  },

  mounted(){
    this.$store.dispatch('form/setFieldValue', {
      field: this.component.id, 
      value: this.status
    });
  }
}
</script>
<style lang="scss">
.push-checkbox-form{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // align-items: center;
  padding: 8px 0;
  margin: 0 16px;
  .mdi-checkbox-blank-outline{
    &.v-icon{
      color: #DDE1E5;
    }
  }
  p {
    flex-grow: 1;
    flex-basis: 80%;
    color: #151C22;
    user-select: none;
  }
  .error-msg{
    color: #F5463B;
    flex-basis: 100%;
  }
}
</style>