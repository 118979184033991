var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"simple-catalog-form"},_vm._l((_vm.items),function(item,k){
var _obj, _obj$1;
return _c('div',{key:item.id + '_' + k,staticClass:"simple-catalog-form__item",style:({
      backgroundColor: _vm.itemBgColor(item),
    }),on:{"click":function($event){item.form_action && !_vm.isLoading && _vm.$emit('formAction', item.form_action )}}},[(_vm.itemHasPersonIcon(item))?_c('svg',{class:'item__left-svg',attrs:{"width":"17","height":"20","viewBox":"0 0 17 20","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M8.247 13.175c-4.313 0-7.997.68-7.997 3.4C.25 19.295 3.91 20 8.247 20c4.313 0 7.997-.68 7.997-3.4 0-2.721-3.66-3.425-7.997-3.425z","fill":"#8897A5"}}),_c('path',{attrs:{"opacity":".4","d":"M8.247 10.584a5.273 5.273 0 005.292-5.292A5.273 5.273 0 008.247 0a5.274 5.274 0 00-5.292 5.292 5.274 5.274 0 005.292 5.292z","fill":"#8897A5"}})]):(_vm.itemImage(item))?_c('div',{staticClass:"item__img-icon"},[_c('img',{attrs:{"height":"24","width":"24","src":_vm.itemImage(item)}})]):_vm._e(),_c('div',{staticClass:"item__text-container"},[_c('p',{staticClass:"item__text-container__title",class:( _obj = {
          'item__text-container__title--mutiline': _vm.multiline_title
        }, _obj[_vm.getItemTitleTextSize(item) || 'small-medium'] = true, _obj ),style:({ 
          color: _vm.getItemTitleColor(item, _vm.isLoading), 
        })},[(item.dot_color)?_c('span',{staticClass:"item__text-container__title__dot",style:({ backgroundColor: item.dot_color })}):_vm._e(),_vm._v(" "+_vm._s(item.title)+" ")]),(item.subtitle)?_c('p',{staticClass:"item__text-container__subtitle",class:( _obj$1 = {
          'item__text-container__subtitle--mutiline': _vm.multiline_subtitle
        }, _obj$1[_vm.getItemSubtitleTextSize(item) || 'caption'] = true, _obj$1 ),style:({ 
          color: _vm.getItemSubtitleColor(item, _vm.isLoading), 
        })},[_vm._v(" "+_vm._s(item.subtitle || '')+" ")]):_vm._e()]),((item.right_icon === 'arrow') || _vm.rightArrow)?_c('svg',{class:'item__right-svg',attrs:{"width":"7","height":"12","viewBox":"0 0 7 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"opacity":".2","d":"M1 11l5-5-5-5","stroke":"#111114","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):(item.right_icon === 'calendar')?_c('svg',{class:'item__right-svg',attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M3.093 9.404h17.824M16.442 13.31h.01M12.005 13.31h.009M7.558 13.31h.01M16.442 17.196h.01M12.005 17.196h.009M7.558 17.196h.01M16.044 2v3.29M7.965 2v3.29","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"clip-rule":"evenodd","d":"M16.238 3.58H7.771C4.834 3.58 3 5.214 3 8.221v9.05C3 20.326 4.834 22 7.771 22h8.458C19.175 22 21 20.355 21 17.348V8.222c.01-3.007-1.816-4.643-4.762-4.643z","stroke":"currentColor","stroke-width":"1.5","stroke-linecap":"round","stroke-linejoin":"round"}})]):_vm._e()])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }