<template>
  <main 
    class="layout__body"
    :class="{
      'layout__body--centered': mainscreen.is_centered || form_get_error
    }"
    :style="{
      backgroundImage: mainscreen.watermark_image,
      backgroundRepeat: mainscreen.watermark_image && 'repeat' || undefined,
    }"
  >
    <template v-if="form_get_error">
      <ErrorPlaceholder 
        :error="form_get_error"
        @retry="getRouteForm"
      />
    </template>
    <template v-else-if="rotated_chart_component">
      <div 
        class="FORMS__card FORMS__card--full-height"
      >
        <component 
          :component="rotated_chart_component"
          :rotated="true"
          :is="componentMap[rotated_chart_component.type] || componentMap['not_found']"
        />
      </div>
    </template>
    <template v-else>
      <CenteredSpinner 
        :value="isSubmitting || form_is_loading"
      />
      <FormContent
        :form_name="form_name" 
        :content="mainscreen.content"
        :errors="mainscreen.errors"
        :values="mainscreen.values"
        :watermark="mainscreen.watermark_image"
        @formAction="handleFormAction"
      />
      <div 
        v-if="showFooter"
        ref="footerEl"
        class="layout__body__footer"
        :style="{
          backgroundColor: mainscreen.footer_color,
        }"
      >
        <FormContent
          v-if="this.mainscreen.footer_content && this.mainscreen.footer_content.length"
          :form_name="form_name" 
          :content="mainscreen.footer_content"
          :errors="mainscreen.errors"
          :values="mainscreen.values"
          @formAction="handleFormAction"
        />
        <FavouriteTogglerRow
          v-if="this.depth > 2"
          :spaced="true"
          :menu_id="menu_id"
        />
      </div>
      <!-- FOOTER SPACER -->
      <div
        v-if="showFooter && footer_height"
        :style="{
          height: footer_height + 'px'
        }"
      ></div>
      <!-- FOOTER SPACER -->
      <Fullscreen 
        v-if="fullscreen.content"
        :watermark="fullscreen.watermark"
        :isContentCentered="fullscreen.is_centered"
        :isLoading="isSubmitting"
        :footerBgColor="fullscreen.footer_color"
        :hasXClose="fullscreen.has_header_x_close"
        @clear="handleFullscreenXClick"
      >
        <FormContent
          :form_name="form_name" 
          :content="fullscreen.content"
          :errors="fullscreen.errors"
          :values="fullscreen.values"
          :watermark="fullscreen.watermark_image"
          @formAction="handleFormAction"
        />
        <template slot="footer">
          <FormContent
            :form_name="form_name" 
            :content="fullscreen.footer_content"
            :errors="fullscreen.errors"
            :values="fullscreen.values"
            @formAction="handleFormAction"
          />
        </template>
      </Fullscreen>
      <BottomDrawer
        v-if="halfscreen.content"
        :notch="true"
        @close="$store.dispatch('form/resetHalfscreenSection')"
      >
        <FormContent
          :form_name="form_name" 
          :content="halfscreen.content"
          :errors="{}"
          :values="{}"
          @formAction="handleFormAction"
        />
      </BottomDrawer>
    </template>
  </main>
</template>

<script>
import bridge from "@/helpers/bridge";
import baseApi from "@/helpers/baseApi";
import { postCustomEvent } from "@/helpers/amplitude.js"
import { handleExternalNavigation } from "@/helpers/utils";
import { setAuthToken } from "@/helpers/auth.js";
import Fullscreen from "@/components/reusable/Fullscreen";
import CenteredSpinner from '@/components/reusable/CenteredSpinner'
import { mapGetters, mapState } from "vuex";
import backNavMixin from "@/mixins/backNav";
import FavouriteTogglerRow from "@/components/reusable/FavouriteTogglerRow";
import BottomDrawer from '../components/reusable/BottomDrawer.vue';
import FormContent from '@/components/FormContent';
import ErrorPlaceholder from '../components/reusable/ErrorPlaceholder.vue';

const validations = {
  required({value = undefined, target}){
    return !!value || (target !== 'true');
  },
  min_length({value = '', target}){
    return value.length > Number(target);
  }
}

export default {
  name: 'FORM_VIEW',
  components: {
    BottomDrawer,
    CenteredSpinner,
    FavouriteTogglerRow,
    FormContent,
    Fullscreen,
    ErrorPlaceholder,
  }, 
  props: [
    'form_name', 
    'menu_id',
    'form_data',
    'form_url',
    'form_url_method',
    'form_url_data',
    'backToName',
    'backToPath',
    'is_edge_case',
    'prev_forms'
    // 'form_request_payload',
  ],
  mixins: [ backNavMixin ],
  data(){
    return {
      form_get_error: undefined,
      form_is_loading: true,
      footer_height: 0,
    }
  },

  computed: {
    ...mapState('layout', [
      'back_link_trigger_value', 
      'rotated_chart_component',
    ]),
    ...mapGetters("mainPage", [
      'get_menu_item_by_id',
      'get_menu_item_depth',
    ]),
    ...mapState('form', [
      'mainscreen',
      'fullscreen',
      'halfscreen',
      'isSubmitting',
      'noScreenshot',
    ]),
    depth(){
      return this.menu_id && this.get_menu_item_depth(this.menu_id) || 0;
    },
    showFooter(){
      return this.mainscreen.footer_content?.length
        || this.depth > 2;
    },
    backLinkRoute(){
      return (this.get_menu_item_by_id(this.menu_id) && 
        this.get_menu_item_by_id(this.menu_id)['parent'] && 
        this.get_menu_item_by_id(this.menu_id)['parent'] >= 0) ? {
        name: 'NavigationItemChild',
        params: {
          menu_id: this.get_menu_item_by_id(this.menu_id)['parent']
        }
      } : {
        name: 'MainPage',
      }
    }
  },

  watch: {
    showFooter: {
      immediate: true,
      handler(){
        this.$nextTick(() => {
          this.footer_height = this.$refs['footerEl']?.offsetHeight || 0;
        })
      },
    },
    form_name(){
      this.handleUpdateForm();
    },
    'back_link_trigger_value'(action){
      if (action){
        this.handleFormAction({ ...action, isBackLinkClick: true });
      }
    }
  },
  
  methods: {
    
    handleUpdateForm(){
      let { dispatch } = this.$store;
      this.form_is_loading = true;
      this.form_get_error = undefined;
      dispatch('form/resetSections');
      dispatch('layout/clearHeaderTitle');
      dispatch('layout/clearHeaderAction');
      dispatch('layout/alert', '');
      this.getForm();
    },

    getRouteForm(){
      if (this.form_url){
        this.getForm({
          url: this.form_url,
          method: this.form_url_method,
          params: this.form_url_data,
        });
      } else {
        this.getForm();
      }
    },

    getForm(options = {}){
      let { method, params, url } = options;
      return baseApi({
        method: method || 'get',
        url: url || `api/form/${this.form_name}`, 
        params,
      }).then((res) => {
        if(res?.data?.form){
          this.setForm(res.data.form);
        } else {
          throw Error(this.$translate('form_error-empty-response'));
        }
      }).catch((err) => {
        console.error(err)
        this.showFormError(err);
      }).finally(() => {
        this.form_is_loading = false;
      })
    },

    handleFormAction(options = {}){
      console.log('form_action handled');
      if (options?.action === 'submit_form'){
        this.submitForm(options)
      } else if (options?.action === 'app_navigate'){
        this.appNavigate(options)
      } else if (options?.action === 'included_form' && options?.hidden_metadata){
        let formData = JSON.parse(options.hidden_metadata);
        console.log('included_form:', formData);
        this.setForm(formData.form, options);
      } else if (options?.action === 'auth_submit'){
        let formData = JSON.parse(options?.hidden_metadata);
        if (formData?.auth_token){
          setAuthToken(formData.auth_token);
          this.$store.dispatch('mainPage/initialize').then(() => {
            this.$router.replace({ name: 'MainPage' });
          });
        } else {
          this.showFormError(new Error(this.$translate('form_no-auth-key')))
        }
      } else if (options?.action === 'qr_submit'){
        this.handleQrAction(options);
      } else if (options?.action === 'open_pdf'){
        console.log('open_pdf options', options)
        this.getPdfLink({
          method: options?.method,
          path: options?.url,
        });
      } else {
        console.error(`ACTION UNKOWN: ${options?.action}`);
        if (options?.isBackLinkClick){
          console.error("Navigating back due to unkown header action");
          this.$router.replace(this.backLinkRoute);
        }
      }
    },

    async submitForm(options = {}){
      let { dispatch } = this.$store;
      console.log('submit options:', options);
      try {
        if (options.isBackLinkClick || options.validate === false){
          console.log('Form validation skipped');
        } else {
          dispatch('form/clearCurrentFieldErrors');
          await this.validateForm().catch((err) => {
            throw err;
          });
          console.log('Form Validated');
        }
        dispatch('form/isSubmitting')
        let axiosOptions = await this.generateSubmitFormAxiosOptions(options);
        let res = await baseApi[axiosOptions.method](
          options.url, 
          axiosOptions.payload, 
          axiosOptions.config
        )
        console.log(res?.data)
        if(res?.data?.form){
          this.setForm(res?.data?.form, options);
        } else {
          throw Error(this.$translate('form_error-empty-response'))
        }
      } catch(err) {
        if (err?.response?.data?.form){
          this.setForm(err.response.data.form, options);
        }
        let popupErr;
        if (err?.response?.status){
          popupErr = `${this.$translate('form_error-word')} ${err?.response?.status}`
        } else {
          popupErr = err.message;
        }
        dispatch('layout/alert', popupErr);
        if (options.isBackLinkClick){
          setTimeout(this.$router.replace(this.backLinkRoute), 1000)
        }
      } finally {
        dispatch('form/isNotSubmitting');
      }
    },
    
    async generateSubmitFormAxiosOptions(options){
      let config;
      let method = options?.method?.toLowerCase() || 'get';
      // determine which values to get - fullscreen or form
      let payload = this.fullscreen.content 
        ? this.fullscreen.values 
        : this.mainscreen.values;
      let extraStorageFieldsList = this.fullscreen.content 
        ? this.fullscreen.values_from_storage 
        : this.mainscreen.values_from_storage;
      let isFileUpload = this.fullscreen.content
        ? this.fullscreen.content.some(form => form.type === "upload_file")
        : this.mainscreen.content.some(form => form.type === "upload_file");
      if (extraStorageFieldsList && extraStorageFieldsList.length > 0){
        let extraFieldsMap = await extraStorageFieldsList.reduce(async (acc,fieldName) => {
          acc = {
            ...acc,
            [fieldName]: await bridge.getStorageItem(fieldName),
          }
          return acc;
        }, {});
        console.log('extraFieldsMap', extraFieldsMap)
        payload = {
          ...payload,
          ...extraFieldsMap,
        }
      }
      if (this.is_edge_case){
        // place edge case data
        payload = {
          ...payload,
          ...this.form_url_data,
        }
      }
      if (isFileUpload){
        config = {
          headers: {
              'content-type': 'multipart/form-data'
          }
        };
        // payload Object to FormData
        console.log('payload in:', payload);
        payload = Object.keys(payload).reduce((dataAccum, currentKey) => {
          let hasFiles = payload[currentKey]?.[0] instanceof File;
          if (hasFiles){
            payload[currentKey].forEach(file => {
              dataAccum.append(currentKey, file);
            })
          } else {
            console.log('not file ', currentKey, payload)
            dataAccum.append(currentKey, payload[currentKey]);
          }
          return dataAccum;
        }, new FormData());
        console.log('payload out:', payload);
      }
      return {
        config,
        method,
        payload,
      }
    },

    async validateForm(){
      return new Promise((resolve, reject) => {
        try {
          let { dispatch } = this.$store;
          let screenType = this.fullscreen.content ? 'fullscreen' : 'mainscreen'
          // TODO rewrite using state in each form 
          let screenValidations = this[screenType].validations;
          let currentFieldValues = this[screenType].values;  
          for (const [form_id, validationsList] of Object.entries(screenValidations)){
            validationsList.forEach((validationItem) => {
              if (validationItem.type === 'count') return;
              let valid = validations[validationItem.type]({
                value: currentFieldValues[form_id],
                target: validationItem.value
              });
              if(this.fullscreen.content){
                dispatch('form/setFullscreenErrors', {
                  ...this.fullscreen.errors,
                  ...(!valid && { [form_id]: validationItem.error } // Adds this if validation func returns false
                  ) 
                })
              } else {
                dispatch('form/setMainscreenErrors', {
                  ...this.mainscreen.errors,
                  ...(!valid && { [form_id]: validationItem.error }
                  ) 
                })
              }
            });
          }
          let FieldErrorTextMap = this[screenType].errors;
          let errorsCount = Object.keys(FieldErrorTextMap).length
          if (errorsCount){
            throw Error(FieldErrorTextMap[Object.keys(FieldErrorTextMap)[0]]);
          }
          resolve()
        } catch (err){
          console.error(err)
          return reject(err)
        }
      })
    },

    appNavigate(options){
      if (options?.url === 'home'){
        this.$router.replace({ name: 'MainPage' })
      } else if (options?.url === 'form' || options?.url === 'service') {
        let form_name = options?.hidden_metadata
        let parent_id = this.$store.getters['mainPage/get_menu_item_by_form'](form_name)?.id;
        let route = {}

        if (parent_id) {
          route = {
            path: options?.url === 'form' ? `/m/${parent_id}/f/${form_name}` : `/m/${parent_id}/s/${form_name}`,
            query: options.params,
          }
        } else {
          route = {
            path: options?.url === 'form' ? `/f/${form_name}` : `/s/${form_name}`,
            query: options.params,
          }
        }

        this.$router.replace(route).catch(() => {
          // If location is the same
          this.handleUpdateForm();
        })
      } else if (options?.url === 'service') {
        let form_name = options?.hidden_metadata
        let parent_id = this.$store.getters['mainPage/get_menu_item_by_form'](form_name)?.id;
        this.$router.replace({ 
          path: parent_id 
            ? `/m/${parent_id}/s/${form_name}`
            : `/s/${form_name}`,
          query: options.params,
        }).catch(() => {
          // If location is the same
          this.handleUpdateForm();
        })
      }
      else if (options?.url === 'close'){
        this.handleFullscreenXClick();
      } else if (options?.url === 'auth'){
        this.setForm(this.form_data);
      }
    },

    setForm(form, options){
      let { dispatch } = this.$store;
      if (options?.isBackLinkClick) {
        if (!form?.options?.half_screen){
          dispatch('form/resetSections');
        }
        this.form_get_error = undefined;
      }
      if (form?.options?.storage_set) this.setStorage(form?.options?.storage_set);
      if(form?.options?.fullscreen){
        if (this.fullscreen.content) dispatch('form/resetFullscreenSection');
        dispatch('form/setFullscreenForm', form);
      } else if (form?.options?.half_screen){
        dispatch('form/setHalfScreenForm', form);
      } else if(form?.content){
        if (this.fullscreen.content) dispatch('form/resetFullscreenSection');
        if (this.halfscreen.content) dispatch('form/resetHalfscreenSection');
        this.setDefaultForm(form);
      }
      // handling external link res
      if (form?.options?.external_navigate){
        handleExternalNavigation(form.options.external_navigate);
      }
    },

    setDefaultForm(form){
      this.$store.dispatch('form/setMainscreenForm', form)
      this.$store.dispatch('layout/setHeaderTitle', form?.header?.title);
      this.$store.dispatch('layout/clearHeaderAction');
      this.$nextTick(() => {
        this.$store.dispatch('layout/setHeaderAction', form?.header?.back_arrow);
      })
    },

    setStorage(items){
      if (items !== Object(items)) {
        this.$store.dispatch('layout/alert', 'Не верный формат storage_set');
      } else {
        Object.keys(items).forEach((key) => {
          bridge.setStorageItem(key, items[key]);
        });
      }
    },

    handleFullscreenXClick(){
      if (this.mainscreen.content.length) {
        this.$store.dispatch('form/closeFullscreen')
      } else {
        let backLink;
        if (this.backToName){
          backLink = {
            name: this.backToName,
          }
        } else if (this.backToPath){
          backLink = { 
            path: this.backToPath
          }
        } else {
          backLink = this.backLinkRoute;
        }
        this.$router.replace(backLink);
      }
    },

    showFormError(err){
      this.form_get_error = err;
      this.$store.dispatch('layout/alert', err);
    },
    
    async handleQrAction(options){
      console.log(options);
      try {
        let { url, method = 'POST' } = options;
        let data;
        if (process.env.NODE_ENV === "development"){
          data = window.prompt('Введите данные QR');
        } else {
          data = await bridge.getQr();
        }
        this.form_is_loading = true;
        let res = await baseApi({method, url, data: { scan: data } });
        if (res?.data?.form){
          if (!this.mainscreen.content || !this.mainscreen.content.length){
            postCustomEvent('Открыта форма из QR');
          }
          this.setForm(res.data.form);
        }
      } catch(err){
        if (err?.response?.data?.form){
          this.setForm(err.response.data.form);
        } else if (!this.mainscreen.content || !this.mainscreen.content.length){
          this.$router.replace({
            name: 'MainPage',
          });
        } else {
          this.$store.dispatch('layout/alert', err);
        }
      } finally {
        this.form_is_loading = false;
      }
    },

    async getPdfLink(options){
      let { path, method = 'get' } = options;
      this.$store.dispatch('form/isSubmitting')
      try {
        console.log('requesting pdf link \n', path);
        let res = await baseApi(path, {
          method,
        });
        if (res?.data?.url){
          console.log("pdf path \n", res.data);
          this.$router.replace({
            name: 'PdfPage',
            query: {
              path: res?.data?.url,
            },
            params: {
              // Used in Pdf back action
              prev_form_data: {
                form_name: this.form_name,
                menu_id: this.menu_id,
                default: {
                  content: this.mainscreen.content,
                  footer_content: this.mainscreen.footer_content,
                  header: {
                    title: this.$store.state.layout.header_title,
                    back_arrow: this.$store.state.layout.header_action,
                  },
                },
                fullscreen: {
                  content: this.fullscreen.content,
                  options: {
                    watermark: !!this.fullscreen.watermark,
                    watermark_text: this.fullscreen.watermark,
                    fullscreen_close: this.fullscreen.has_header_x_close
                  }
                }
              }
            }
          })
        } else if (res?.data?.form){
          this.setForm(res.data.form);
        } else {
          throw new Error( this.$translate('form_pdf-response-format-error'))
        }
      } catch (e){
        console.error(e);
        this.$store.dispatch('layout/alert', e);
      } finally{
        this.$store.dispatch('form/isNotSubmitting')
      }
    },

  },

  mounted(){
    let { dispatch } = this.$store;
    if (this.form_name === 'qr_scanner' || this.form_name === 'qr_scanner_2'){
      this.handleQrAction({ url: 'api/feedback/qr_code_scanner/', method: 'POST'})
    } else if(this.prev_forms) {
      dispatch('form/setFullscreenForm', this.prev_forms.fullscreen);
      this.setDefaultForm(this.prev_forms.default);
      this.form_is_loading = false;
    } else if (this.form_data){
      // sets only one form
      this.setForm(this.form_data);
      this.form_is_loading = false;
    } else {
      this.getRouteForm();
    }
  },

  destroyed(){
    let { dispatch } = this.$store;
    dispatch('form/reset');
    dispatch('layout/clearHeaderTitle');
    dispatch('layout/clearHeaderAction');
    dispatch('layout/clearRotatedChartComponent');
    if (this.noScreenshot){
      dispatch('form/enableScreenshot');
    }
  },

  beforeRouteUpdate(to, from, next){
    let { form_name } = to.params;
    if (form_name === 'erg_way_form') {
      this.$router.replace({ name: 'Way' });
    } else if (form_name === 'my_calendar_form') {
      this.$router.replace({ name: 'NavigationItemCalendarFormPage' });
    } else if (form_name === 'test_survey_form' || form_name === 'survey_form') {
      this.$router.replace({ 
        name: 'SurveyByIdPage',
        params: {
          id: to.query.survey_id
        },
        query: {
          is_anonymous: to.query.is_anonymous,
        } 
      });
    } else {
      next();
    }
  }

}
</script>
<style lang="scss">
.FORMS__card{
  display: flex;
  flex-direction: column;  
  background: #FFFFFF;
  padding: 16px 0;
  margin: 0 0 16px;
  border-radius: 12px;
  &--full-height{
    height: 100%;
  }
}
.FORMS__custom-container{
  display: flex;
  margin-left: 16px;
  margin-right: 16px;
  >div {
    flex: 0 1 50%;
    margin-left: 0;
    margin-right: 0;
    &:nth-child(odd){
      padding-right: 4px;
    }
    &:nth-child(even){
      padding-left: 4px;
    }
  }
}
.layout__body__footer{
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 3;
}
</style> 