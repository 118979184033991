<template>
  <div 
    class="radio-group-form"
    :class="{
      'radio-group-form--horizontal': horizontal,
    }"
  >
    <p
      v-if="title" 
      class="radio-group-form__title small"
    >{{ title }}</p>
    <div 
      class="radio-group-form__item"
      v-for="(i, k) in items"
      :key="k+'_'+i.id"
      @click="handleItemClick(i.id)"
    >
      <div 
        class="radio-group-form__item__circle"
        :class="{
          'radio-group-form__item__circle--active': i.id === value,
        }"
      >
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="i.id === value" v-bind:svg-inline="''" v-bind:width="'100%'" v-bind:height="'100%'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.477 0 0 4.477 0 10s4.477 10 10 10 10-4.477 10-10S15.523 0 10 0zm4.757 7.653a1 1 0 00-1.514-1.306l-4.452 5.16-2.063-2.192a1 1 0 10-1.456 1.37l2.823 3a1 1 0 001.486-.032l5.176-6z" fill="#267CCC"/></svg>
      </div>
      <span class="radio-group-form__item__text">{{ i.title }}</span>
    </div>
    <p v-if="error_msg" class="FORMS__error-msg">{{error_msg}}</p>
  </div>
</template>

<script>
export default {
  name: 'RadioGroupForm',
  props: ['component', 'value', 'error_msg'],
  data(){
    return {
      title: this.component?.title,
      items: this.component?.items,
      horizontal: this.component?.options?.orientation === 'horizontal',
    }
  },
  methods: {
    
    handleItemClick(value){
      this.$store.dispatch('form/setFieldValue', {
        field: this.component.id, 
        value
      });
    },

  },
  mounted(){
    if (this.component.default_value){
      this.$store.dispatch('form/setFieldValue', {
        field: this.component.id, 
        value: this.component.default_value.id
      });
    }
  }
}
</script>

<style lang="scss">
.radio-group-form{
  display: flex;
  flex-direction: column;
  padding: 8px 0;
  margin: 0 16px;
  &--horizontal{
    @extend .radio-group-form;
    flex-direction: row;
    flex-wrap: wrap;
    .radio-group-form__title{
      flex: 1 0 100%;
    }
    .radio-group-form__item{
      flex: 1 0 50%;
      &__text{
        margin-right: 8px;
      }
    }
  }
  &__title{
    color: #667A8B;
    margin: 0 0 8px;
  }
  &__item{
    display: flex;
    align-items: center;
    padding: 8px 0;
    &__circle {
      flex: 0 0 auto;
      width: 20px;
      height: 20px;
      overflow: hidden;
      border-radius: 50%;
      border: 2px solid #DDE1E5;
      &--active {
        border: none;
      }
    }
    &__text {
      font-family: 'TTCommons';
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.02em;
      color: #151C22;
      margin-left: 12px;

    }
  }
}
</style>