<template>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="icon === 'calendar'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.093 9.404h17.824M16.442 13.31h.01M12.005 13.31h.009M7.558 13.31h.01M16.442 17.196h.01M12.005 17.196h.009M7.558 17.196h.01M16.044 2v3.29M7.965 2v3.29" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M16.238 3.58H7.771C4.834 3.58 3 5.214 3 8.221v9.05C3 20.326 4.834 22 7.771 22h8.458C19.175 22 21 20.355 21 17.348V8.222c.01-3.007-1.816-4.643-4.762-4.643z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'clock'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'user'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5 20v-1a7 7 0 017-7v0a7 7 0 017 7v1M12 12a4 4 0 100-8 4 4 0 000 8z" stroke="#667A8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'copy'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.25 15.75H6.375A1.875 1.875 0 014.5 13.875v-7.5A1.875 1.875 0 016.375 4.5h7.5a1.875 1.875 0 011.875 1.875V8.25M10.125 19.5h7.5a1.875 1.875 0 001.875-1.875v-7.5a1.875 1.875 0 00-1.875-1.875h-7.5a1.875 1.875 0 00-1.875 1.875v7.5a1.875 1.875 0 001.875 1.875z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'gay_couple'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.25 15a4.875 4.875 0 100-9.75 4.875 4.875 0 000 9.75zM14.57 5.432A4.876 4.876 0 1115.893 15M1.5 18.506a8.252 8.252 0 0113.5 0M15.893 15a8.24 8.24 0 016.75 3.505" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'link'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.818 15.181l6.364-6.364M13.59 16.773l-2.651 2.651a4.5 4.5 0 01-6.364-6.363l2.651-2.652M16.773 13.59l2.651-2.651a4.5 4.5 0 00-6.363-6.364l-2.652 2.651" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'user-plus'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 15a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5zM5.981 18.691a6.753 6.753 0 0112.038 0M16.5 5.25H21M18.75 3v4.5M20.889 10.58a8.991 8.991 0 11-7.47-7.469" stroke="#667A8B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'email'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M22 6c0-1.1-.9-2-2-2H4c-1.1 0-2 .9-2 2m20 0v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6m20 0l-10 7L2 6" stroke="#EF7F1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'phone'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 1.5H8.75A2.25 2.25 0 006.5 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5a2.25 2.25 0 002.25-2.25V3.75a2.25 2.25 0 00-2.25-2.25H14m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" stroke="#EF7F1A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'face_id'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g clip-path="url(#clip0_4735_49817)"><path d="M1.486 8.316c.65 0 .992-.367.992-1.004V4.42c0-1.274.674-1.923 1.9-1.923h2.964c.65 0 1.005-.355 1.005-.992 0-.638-.356-.98-1.005-.98h-2.99C1.793.524.507 1.785.507 4.31v3.002c0 .637.355 1.004.98 1.004zm22.015 0c.65 0 .993-.367.993-1.004V4.31c0-2.524-1.287-3.786-3.847-3.786h-3.002c-.637 0-.992.343-.992.98 0 .638.355.993.992.993h2.965c1.213 0 1.911.65 1.911 1.923v2.892c0 .637.355 1.004.98 1.004zM4.353 24.5h2.989c.65 0 1.005-.355 1.005-.98 0-.637-.356-.993-1.005-.993H4.377c-1.225 0-1.899-.649-1.899-1.923v-2.891c0-.65-.355-1.005-.992-1.005s-.98.355-.98 1.005v2.989c0 2.536 1.286 3.798 3.847 3.798zm13.292 0h3.002c2.56 0 3.847-1.274 3.847-3.798v-2.99c0-.649-.356-1.004-.993-1.004s-.98.355-.98 1.005v2.891c0 1.274-.698 1.923-1.911 1.923h-2.965c-.637 0-.992.356-.992.993 0 .625.355.98.992.98zM7.832 11.685c.576 0 .992-.404.992-.992V9.027c0-.588-.416-.993-.992-.993-.576 0-.98.405-.98.993v1.666c0 .588.404.992.98.992zm3.688 2.891h.098c1.237 0 1.91-.673 1.91-1.91v-3.86c0-.478-.318-.784-.783-.784-.49 0-.809.306-.809.784v3.97c0 .122-.073.183-.184.183h-.428a.774.774 0 00-.784.784c0 .527.355.833.98.833zm5.61-2.89c.564 0 .968-.405.968-.993V9.027c0-.588-.404-.993-.967-.993-.588 0-.993.405-.993.993v1.666c0 .588.405.992.993.992zm-4.704 7.007c1.483 0 2.977-.637 4.043-1.703.147-.135.22-.343.22-.588a.773.773 0 00-.796-.797c-.232 0-.404.086-.637.331-.674.699-1.74 1.177-2.83 1.177a4.083 4.083 0 01-2.818-1.177c-.196-.183-.355-.33-.649-.33a.773.773 0 00-.796.796c0 .208.073.404.233.576.992 1.127 2.548 1.715 4.03 1.715z" fill="#EF7F1A"/></g><defs><clipPath id="clip0_4735_49817"><path fill="#fff" transform="translate(.506 .5)" d="M0 0h23.988v24H0z"/></clipPath></defs></svg>
</template>

<script>
export default {
  name: 'FormIcon',
  props: ['icon'],
}
</script>