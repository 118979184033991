<template>
  <v-row class="progress-form">
    <v-col
      v-for="(i, k) in items"
      cols="4"  
      :key="k"
    >
      <div 
        class="progress-form__item"
      >
        <p 
          class="caption mb-8"
          :style="{
            textAlign: 'center',

          }"  
        >{{ i.title }}</p>
        <ProgressCircular 
          :color="i.color"
          :value="i.value"
        />
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ProgressCircular from '@/components/Forms/partials/ProgressCircular'

export default {
  name: "ProgressForm",
  props: [ 'component' ],
  data(){
    return {
      items: this.component.content,
    }
  },
  components: {
    ProgressCircular
  }
}
</script>
<style lang="scss">
.progress-form{
  margin: 0 8px;
  &__item{
    height: 100%;
    max-width: 64px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    margin: 0 auto 4px;
  }
}
</style>