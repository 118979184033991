<template>
  <div class="FORMS__text-input-container">
    <input 
      class="FORMS__text-input"
      :class="{
        'FORMS__text-input--disabled': isLoading
      }"
      :disabled="isLoading"
      :autocomplete="inputType === 'password' ? 'off' : undefined"
      ref="textarea" 
      rows="1"
      :placeholder="placeholder"
      :maxlength="maxLength"
      :type="inputType"
      v-mask="mask"
      :pattern="(inputType === 'number') ? '\d*' : undefined"
      v-model="computedValue"
      @focus="$store.dispatch('layout/hideFooter')"
      @blur="$store.dispatch('layout/showFooter')"
    >
    <p v-if="error_msg" class="FORMS__error-msg">{{error_msg}}</p>
  </div>
</template>
<script>
// https://vuetifyjs.com/en/components/textareas/#usage
export default {
  name: 'TextInputForm',
  props: ['component', 'error_msg', 'isLoading'],
  data(){
    return {
      inputType: this.component?.options?.secret_input 
        ? 'password'
        : this.component?.options?.input_type,
      mask: this.component?.mask,
      maxLength: this.component?.options?.max_length,
      options: this.component.options, 
      placeholder: this.component.placeholder,
      value: this.component.text, 
    }
  },
  computed: {
    computedValue: {
      get(){
        return this.value;
      },
      set(newValue){
        this.value = newValue;
        this.$store.dispatch('form/setFieldValue', { 
          field: this.component.id, 
          value: newValue
        });
      },
    }
  },
  mounted(){
    // fixes issue when default value is set, but the parent component validation is not passed
    if (this.value){
      this.$store.dispatch('form/setFieldValue', {
        field: this.component.id, 
        value: this.value
      })
    }
  }
}
</script>

<style lang="scss">
.FORMS__text-input-container{
  margin: 0.375rem 16px;
  .FORMS__text-input{
    background: #FFFFFF;
    border: 1px solid #F9F9F9;
    width:100%;
    padding: 14px 16px;
    // border: 1px solid #DDE1E5;
    box-sizing: border-box;
    border-radius: 16px;
  }
  .FORMS__text-input--disabled{
    color: #DDE1E5;
  }
}
</style>