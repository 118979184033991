var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"layout__body",class:{
    'layout__body--centered': _vm.mainscreen.is_centered || _vm.form_get_error
  },style:({
    backgroundImage: _vm.mainscreen.watermark_image,
    backgroundRepeat: _vm.mainscreen.watermark_image && 'repeat' || undefined,
  })},[(_vm.form_get_error)?[_c('ErrorPlaceholder',{attrs:{"error":_vm.form_get_error},on:{"retry":_vm.getRouteForm}})]:(_vm.rotated_chart_component)?[_c('div',{staticClass:"FORMS__card FORMS__card--full-height"},[_c(_vm.componentMap[_vm.rotated_chart_component.type] || _vm.componentMap['not_found'],{tag:"component",attrs:{"component":_vm.rotated_chart_component,"rotated":true}})],1)]:[_c('CenteredSpinner',{attrs:{"value":_vm.isSubmitting || _vm.form_is_loading}}),_c('FormContent',{attrs:{"form_name":_vm.form_name,"content":_vm.mainscreen.content,"errors":_vm.mainscreen.errors,"values":_vm.mainscreen.values,"watermark":_vm.mainscreen.watermark_image},on:{"formAction":_vm.handleFormAction}}),(_vm.showFooter)?_c('div',{ref:"footerEl",staticClass:"layout__body__footer",style:({
        backgroundColor: _vm.mainscreen.footer_color,
      })},[(this.mainscreen.footer_content && this.mainscreen.footer_content.length)?_c('FormContent',{attrs:{"form_name":_vm.form_name,"content":_vm.mainscreen.footer_content,"errors":_vm.mainscreen.errors,"values":_vm.mainscreen.values},on:{"formAction":_vm.handleFormAction}}):_vm._e(),(this.depth > 2)?_c('FavouriteTogglerRow',{attrs:{"spaced":true,"menu_id":_vm.menu_id}}):_vm._e()],1):_vm._e(),(_vm.showFooter && _vm.footer_height)?_c('div',{style:({
        height: _vm.footer_height + 'px'
      })}):_vm._e(),(_vm.fullscreen.content)?_c('Fullscreen',{attrs:{"watermark":_vm.fullscreen.watermark,"isContentCentered":_vm.fullscreen.is_centered,"isLoading":_vm.isSubmitting,"footerBgColor":_vm.fullscreen.footer_color,"hasXClose":_vm.fullscreen.has_header_x_close},on:{"clear":_vm.handleFullscreenXClick}},[_c('FormContent',{attrs:{"form_name":_vm.form_name,"content":_vm.fullscreen.content,"errors":_vm.fullscreen.errors,"values":_vm.fullscreen.values,"watermark":_vm.fullscreen.watermark_image},on:{"formAction":_vm.handleFormAction}}),_c('template',{slot:"footer"},[_c('FormContent',{attrs:{"form_name":_vm.form_name,"content":_vm.fullscreen.footer_content,"errors":_vm.fullscreen.errors,"values":_vm.fullscreen.values},on:{"formAction":_vm.handleFormAction}})],1)],2):_vm._e(),(_vm.halfscreen.content)?_c('BottomDrawer',{attrs:{"notch":true},on:{"close":function($event){return _vm.$store.dispatch('form/resetHalfscreenSection')}}},[_c('FormContent',{attrs:{"form_name":_vm.form_name,"content":_vm.halfscreen.content,"errors":{},"values":{}},on:{"formAction":_vm.handleFormAction}})],1):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }