<template>
  <div class="qr-code-form">
    <QrCode
      background="transparent"
      class="qr-code-form__qr-code"
      :margin="1"
      :render-as="'svg'"
      :size="280"
      :value="component.url" />
  </div>
</template>

<script>
import QrCode from 'qrcode.vue';

export default {
  name: "QrCodeForm",
  props: [ 'component' ],
  components: {
    QrCode,
  }
}
</script>

<style lang="scss" scoped>
.qr-code-form{
  display: flex;
  justify-content: center;
  padding: 16px 0;
  margin: 0 16px;
  &__qr-code{
    display: block;
    margin: 0 auto;
  }
}
</style>