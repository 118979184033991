<template>
  <div class="pin-input-form">
    <input 
      v-for="(i,k) in pinValues"
      :key="k"
      :data-key="k"
      ref="pinInputs"
      autocomplete="off"
      class="pin-input-form__input-box"
      type="text" 
      inputmode="numeric"
      pattern="\d*"
      maxlength="1"
      spellcheck="false"
      :value="pinValues[k]"
      :disabled="isLoading"
      @focus="$set(pinValues, k, undefined);"
      @keydown="handleKeydownById($event, k)"
    />
  </div>
</template>

<script>
export default {
  name: 'PinForm',
  props: {
    component: {
      type: Object,
    },
    isLoading: {
      type: Boolean, 
      default: false,
    },
    value: {
      type: String,
      default: '',
    }
  },

  data(){
    return {
      // set values via $set only:
      pinValues: new Array(this.component.length),
      length: this.component.length,
    }
  },

  watch: {
    value(val){
      if (val.length === this.length){
        this.$emit('formAction', this.component.form_action);
      }
    }
  },

  mounted(){
    this.$refs.pinInputs[0].focus();
    this.$refs.pinInputs.forEach((inputEl) => {
      inputEl.addEventListener('paste', this.onPaste);
      inputEl.addEventListener('compositionupdate', this.onCompositionUpdate);
    });
  },

  beforeDestroy(){
    this.$refs.pinInputs.forEach((inputEl) => {
      inputEl.removeEventListener('paste', this.onPaste);
      inputEl.removeEventListener('compositionupdate', this.onCompositionUpdate);
    });
  },

  methods: {

    onPaste(e){
      e.stopPropagation();
      e.preventDefault();
      let clipboardTextData = (e.clipboardData || window.clipboardData).getData('Text');
      if (clipboardTextData.length > this.length){
        this.$store.dispatch('layout/alert', 'Ошибка: длина текста превышает количество символов');
      } else if (!clipboardTextData.match(/\d/)){
        this.$store.dispatch('layout/alert', 'Ошибка: текст должен сосотоять из цифр')
      } else {
        clipboardTextData.split('').forEach((el, k) => {
          this.$set(this.pinValues, k, el);
        });
        if (clipboardTextData.length < this.length){
          this.$refs.pinInputs[clipboardTextData.length].focus();
        }
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: this.pinValues.join(''),
        });
      }
    },

    onCompositionUpdate(e){
      console.log(e);
      e.preventDefault();
      let val = e.data;
      let id = Number(e.target.dataset.key);
      if (!val.match(/\d/)) return;
      this.$set(this.pinValues, id, val);
      if (id < this.pinValues.length - 1){
        this.$refs.pinInputs[id+1].focus();
      }
    },

    handleKeydownById(e, id){
      e.preventDefault();
      let { key } = e;
      console.log(e);
      if (key === 'Backspace') {
        if (id > 0){
          this.$set(this.pinValues, id, '');  
          this.$refs.pinInputs[id-1].focus();
        }
      } else {
        if (!key.match(/\d/)) return;
        this.$set(this.pinValues, id, key);
        if (id < this.pinValues.length - 1){
          this.$refs.pinInputs[id+1].focus();
        }
      }
      this.$store.dispatch('form/setFieldValue', {
        field: this.component.id, 
        value: this.pinValues.join(''),
      });
    },

  }
}
</script>

<style lang="scss">
.pin-input-form{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin: 0.375rem 16px;
  &__input-box {
    min-width: 0;
    flex: 1;
    height: 56px;
    background: #FFFFFF;
    border-radius: 8px;
    font-family: 'TTCommons';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #000000;
    padding: 14px 0;
    text-align: center;
    -moz-appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    &:focus{
      border: none;
      outline: none;
    }
    &:focus-visible{
      border: none;
      outline: none;
      box-shadow: inset 0px -2px 0px #EF7F1A;
    }
  }
}
</style>