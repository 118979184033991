<template>
  <div>
    <template 
      v-for="(component, k) in content"
    >
      <div 
        v-if="component.type === 'card'"
        :key="'card_'+k+'_'+component.id"
        class="FORMS__card"
        :style="{
          backgroundImage: watermark,
          backgroundRepeat: watermark && 'repeat' || undefined,
        }"
      >
        <component 
          v-for="(cardComponent, card_item_k) in component.content"
          :component="cardComponent"
          :error_msg="errors[cardComponent.id]"
          :is="componentMap[cardComponent.type] || componentMap['not_found']"
          :isLoading="isSubmitting"
          :key="form_name+'_'+cardComponent.type+'_'+k+'_'+card_item_k"
          :value="values[component.id]"
          @formAction="handleFormAction"
        />
      </div>
      <div 
        v-else-if="component.type === 'custom_container'"
        :key="'custom-container_'+k+'_'+component.id"
        class="FORMS__custom-container"
      >
        <component 
          v-for="(customContainerComponent, custom_container_item_k) in component.content"
          :component="customContainerComponent"
          :error_msg="errors[customContainerComponent.id]"
          :is="componentMap[customContainerComponent.type] || componentMap['not_found']"
          :isLoading="isSubmitting"
          :key="form_name+'_'+customContainerComponent.type+'_'+k+'_'+custom_container_item_k"
          :value="values[component.id]"
          @formAction="handleFormAction"
        />
      </div>
      <component 
        v-else
        :component="component"
        :error_msg="errors[component.id]"
        :is="componentMap[component.type] || componentMap['not_found']"
        :isLoading="isSubmitting"
        :key="form_name+'_'+component.type+'_'+k+'_'+component.id"
        :value="values[component.id]"
        @formAction="handleFormAction"
      />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FORMS from "@/components/Forms/index.js";
export default {
  name: 'FormContent',
  props: [
    'form_name',
    'content',
    'errors',
    'values',
    'watermark'
  ],
  data(){
    return {
      componentMap: {
        ...FORMS
      },
    }
  },
  computed: {
    ...mapState('form', [
      'isSubmitting',
    ]),
    // errors(){
    //   if (this.section === 'halfscreen'){
    //     return this.fullscreen.content ? this.fullscreen.errors : this.mainscreen.errors;
    //   } else {
    //     return this.$store.state.form[this.section].errors;
    //   }
    // },
  },
  methods: {
    handleFormAction(...rest){
      this.$emit('formAction', ...rest)
    }
  }
}
</script>

<style>

</style>