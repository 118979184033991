<template>
  <div 
    @click="(formAction && !isLoading) ? $emit('formAction', formAction ) : null"
    class="auth-submit-form"
    :class="{
      'auth-submit-form--vertical': vertical,
    }"
  >
    <div class="auth-submit-form__left">
      <FormIcon :icon="icon" />
    </div>
    <div class="auth-submit-form__right">
      <p class="auth-submit-form__title">{{ title }}</p>
      <p class="auth-submit-form__subtitle">{{ subtitle }}</p>
    </div>
  </div>
</template>
<script>
import FormIcon from './partials/FormIcon'
export default {
  name: 'AuthSubmitForm',
  components: {
    FormIcon
  },
  props: ['component', 'isLoading'],
  data(){
    return {
      formAction: this.component?.form_action,
      title: this.component?.title,
      subtitle: this.component?.subtitle,
      icon: this.component?.options?.item_left_icon_resource,
      vertical: this.component?.options?.vertical,
    }
  },
}
</script>
<style lang="scss">
.auth-submit-form{
  display: flex;
  background: #FFFFFF;
  border-radius: 50px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 14px 16px;
  margin: 0 24px 12px;
  gap: 12px;
  min-width: 0;
  &__left{
    flex: 0 0 auto;
  }
  &__right{
    flex: 1 0;
  }
  &__title{
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0em;
  }
  &__subtitle{
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }
  &--vertical{
    flex-direction: column;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.04), 0px 8px 16px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 16px;
  }
}
</style>