<template>
  <div class="icon-form">
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="icon === 'airplane'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M37.5 2l-36 14.823 14.823 6.354L22.677 38 37.5 2z" fill="#EF7F1A" fill-opacity=".16"/><path d="M37.5 2l-36 14.823 14.823 6.354M37.5 2L22.677 38l-6.354-14.823M37.5 2L16.323 23.177" stroke="#EF7F1A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="37" height="36" viewBox="0 0 37 36" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'bell'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M27.7 16.5C28.573 24.563 32 27 32 27H5s4.5-3.2 4.5-14.4c0-2.546.948-4.988 2.636-6.788C13.824 4.012 16.113 3 18.5 3a8.45 8.45 0 011.5.134M29 12a4.5 4.5 0 100-9 4.5 4.5 0 000 9zM21.095 31.5a3 3 0 01-5.19 0" stroke="#8E8E93" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'check'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.5 38c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z" fill="#90BC34" fill-opacity=".16"/><path d="M29.5 14l-14 14-6-6m28-2c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18z" stroke="#90BC34" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'clock'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.5 38c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z" fill="#EF7F1A" fill-opacity=".16"/><path d="M19.5 12v8l4 4m14-4c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18z" stroke="#EF7F1A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'cross'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M19.5 38c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z" fill="#F5463B" fill-opacity=".16"/><path d="M6.772 7.272l25.456 25.456M37.5 20c0 9.941-8.059 18-18 18s-18-8.059-18-18 8.059-18 18-18 18 8.059 18 18z" stroke="#F5463B" stroke-width="2.5" stroke-linecap="round"/></svg>
    <svg width="57" height="56" viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'gear'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M28.528 34.604c-3.744 0-6.772-2.917-6.772-6.58 0-3.664 3.028-6.604 6.772-6.604s6.7 2.94 6.7 6.604c0 3.663-2.956 6.58-6.7 6.58z" fill="#0086E7"/><path opacity=".4" d="M50.037 33.53c-.453-.7-1.097-1.4-1.932-1.843-.667-.327-1.097-.863-1.478-1.493-1.216-2.007-.501-4.644 1.526-5.834a4.73 4.73 0 001.764-6.603l-1.597-2.753a4.93 4.93 0 00-6.7-1.774c-2.1 1.12-4.794.374-6.01-1.61-.382-.653-.596-1.353-.548-2.053.071-.91-.215-1.773-.644-2.473-.883-1.447-2.48-2.427-4.245-2.427h-3.362a5.12 5.12 0 00-4.22 2.427 4.164 4.164 0 00-.668 2.473c.047.7-.167 1.4-.549 2.053-1.216 1.984-3.91 2.73-5.985 1.61a4.957 4.957 0 00-6.725 1.774l-1.597 2.753A4.77 4.77 0 008.83 24.36c2.027 1.19 2.743 3.827 1.55 5.834-.405.63-.834 1.166-1.502 1.493-.81.443-1.526 1.143-1.908 1.843-.882 1.447-.834 3.267.048 4.784l1.645 2.8a4.979 4.979 0 004.245 2.426c.81 0 1.764-.233 2.528-.7.596-.396 1.311-.536 2.098-.536 2.36 0 4.34 1.936 4.388 4.246 0 2.684 2.193 4.784 4.96 4.784h3.242c2.743 0 4.937-2.1 4.937-4.784.071-2.31 2.05-4.246 4.411-4.246.763 0 1.478.14 2.098.536.764.467 1.694.7 2.528.7 1.693 0 3.339-.933 4.22-2.426l1.67-2.8c.858-1.564.93-3.337.048-4.784z" fill="#0086E7"/></svg>
    <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="icon === 'search'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M33.265 17.882c0 8.772-7.111 15.883-15.883 15.883-8.771 0-15.882-7.111-15.882-15.883C1.5 9.111 8.61 2 17.382 2c8.772 0 15.883 7.11 15.883 15.882z" fill="#EF7F1A" fill-opacity=".16"/><path d="M37.5 38l-8.887-8.887m4.652-11.23C33.265 9.11 26.154 2 17.382 2 8.611 2 1.5 9.11 1.5 17.882c0 8.772 7.11 15.883 15.882 15.883 8.772 0 15.883-7.111 15.883-15.883z" stroke="#EF7F1A" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </div>
</template>

<script>
export default {
  name: "IconForm",
  props: ['component'],
  data(){
    return {
      icon: this.component?.name
    }
  }
}
</script>
<style>
.icon-form{
  padding: 28px 0 20px;
  margin: 0 auto;
  text-align: center;
}
</style>