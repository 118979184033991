<template>
  <div class="mx-16">
    <textarea 
      class="textarea-form"
      :class="{
        'textarea-form--disabled': isLoading
      }"
      ref="textarea" 
      name="" 
      id="" 
      rows="1"
      :disabled="isLoading"
      v-model="computedValue"
      :placeholder="placeholder"
      :maxlength="options && options.max_length"
      @focus="$store.dispatch('layout/hideFooter')"
      @blur="$store.dispatch('layout/showFooter')"
    ></textarea>
    <p v-if="error_msg" class="FORMS__error-msg">{{error_msg}}</p>
  </div>
</template>

<script>
// https://vuetifyjs.com/en/components/textareas/#usage
export default {
  name: 'TextAreaForm',
  props: ['component', 'error_msg', 'isLoading'],
  data(){
    return {
      value: this.component.text, 
      options: this.component.options, 
      placeholder: this.component.placeholder,
    }
  },
  computed: {
    computedValue: {
      get(){
        return this.value;
      },
      set(newValue){
        this.value = newValue;
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: newValue
        });
      },
    }
  },
  watch: {
    value(){
      this.calculateInputHeight();
    },
  },
  methods: {
    calculateInputHeight () {
      const input = this.$refs.textarea;
      console.log(input)
      input.style.height = '0'
      const height = input.scrollHeight;
      const minHeight = 20 * 3 + 28; //parseInt(1, 10) * parseFloat('24')
      console.log(height)
      input.style.height = Math.max(minHeight, height+2) + 'px';
    },
  },
  mounted () {
    // fixes issue when default value is set, but the parent component validation is not passed
    if (this.value){
      this.$store.dispatch('form/setFieldValue', {
        field: this.component.id, 
        value: this.value
      });
    }
    
    setTimeout(() => {
      this.calculateInputHeight()
    }, 0)
  },
}
</script>

<style lang="scss">
.textarea-form{
  background: #FFFFFF;
  border: 1px solid #F9F9F9;
  resize: vertical;
  width:100%;
  padding: 14px 16px;
  // border: 1px solid #DDE1E5;
  box-sizing: border-box;
  border-radius: 12px;
  &.textarea-form--disabled{
    color: #DDE1E5;
  }
}
</style>