<template>
  <div class="checkbox-form">
    <v-simple-checkbox
      ref="checkbox"
      v-model="inputComputedValue"
      :disabled="isLoading"
      color="#267CCC"
      :ripple="false"
    ></v-simple-checkbox>
    <p 
      class="ml-12"
      :class="textSize"
      :style="{ 
        color: textColor 
      }"
      @click="$refs.checkbox.click()"

    >{{ title }}</p>
    <p v-if="error_msg" class="FORMS__error-msg">{{ error_msg }}</p>
  </div>
</template>

<script>
// import { VCheckBox } from ""
export default {
  name: 'CheckboxForm',
  props: ['component', 'error_msg', 'isLoading'],
  data(){
    return {
      id: this.component.id,
      title: this.component.title,
      inputDataValue: this.component.default_state,
      textColor: this.component?.options?.text_color,
      textSize: this.component?.options?.text_size,
      maxValuesAvailable: this.component?.validations_rules?.find(i => i?.type === 'count')?.value,
    }
  },
  computed: {
    inputComputedValue: {
      get(){
        return this.inputDataValue
      },
      set(newVal){
        if (this.maxValuesAvailable && newVal){
          let currentValuesCount = this.$store.getters['form/getCurrentFormCheckboxCheckedCount']
          if (currentValuesCount >= Number(this.maxValuesAvailable)) return;
        }
        this.inputDataValue = newVal;
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: newVal
        });
      }
    }
  },
  mounted(){
    this.$store.dispatch('form/setFieldValue', { 
      field: this.component.id, 
      value: this.inputDataValue 
    });
  }
}
</script>

<style lang="scss">
.checkbox-form{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  // align-items: center;
  padding: 8px 0;
  margin: 0 16px;
  .mdi-checkbox-blank-outline{
    &.v-icon{
      color: #DDE1E5;
    }
  }
  p {
    flex-grow: 1;
    flex-basis: 80%;
    color: #151C22;
    user-select: none;
  }
  .FORMS__error-msg{
    flex-basis: 100%;
  }
}
</style>