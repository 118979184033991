<template>
  <div 
    class="labeled-text-form"
    :class="{
      'labeled-text-form--wrap': isWrap,
      'labeled-text-form--no-copy': isNoCopy,
    }"  
  >
    <p class="caption-medium">{{ label }}</p>
    <p 
      class="labeled-text-form__text"
      :style="{ color }"
    >{{ title }}</p>
  </div>
</template>

<script>
export default {
  name: 'LabeledTextForm',
  props: ['component'],
  data(){
    return {
      label: this.component?.label,
      title: this.component?.title,
      isNoCopy: this.component?.options?.no_copy_text,
      isWrap: this.component?.options?.wrap,
      color: this.component?.options?.color || 
        this.component?.options?.text_color,
    }
  },

}
</script>
<style lang="scss">
.labeled-text-form{
  margin: 0 16px 8px;
  // padding: 16px;
  border-radius: 12px;
  &--wrap{
    padding: 8px 16px;
    background-color: #FFF;
    border: 1px solid #DDE1E5;
    border-radius: 12px;
  }
  &--no-copy{
    user-select: none;
  }
  .caption-medium{
    color: #8897A5;
  }
  &__text{
    white-space: pre-wrap;
    word-break: break-word;
  }
}
</style>