<template>
  <main
    :key="menu_id" 
    class="layout__body"
  >
    <SubMenu 
      :items="get_menu_children(menu_id)"
    />
  </main>
</template>

<script>
import { mapGetters } from "vuex";
import { backNavigateQueue } from "@/helpers/app.js";
import SubMenu from "@/components/Main/SubMenu.vue";

export default {
  name: 'NavigationChildView',
  props: ['menu_id'],
  components: {
    SubMenu
  },
  computed: {
    ...mapGetters('mainPage', [
      'get_menu_children',
      'get_menu_item_by_id', 
      'is_favourite', 
    ])
  },
  watch: {
    
    menu_id: {
      immediate: true,
      handler: 'setAppHeaderTitleByParentId'
    }

  },

  mounted(){
    window.scrollTo(0,0);
    backNavigateQueue.set(this.handleBackClick);
  },

  methods: {
    
    setAppHeaderTitleByParentId(val){
      let title = this.get_menu_item_by_id(val).title; 
      this.$store.dispatch('layout/setHeaderTitle', title);
    },

    handleBackClick(){
      if (this.rotated_chart_component){
        this.$store.dispatch('layout/clearRotatedChartComponent');
      } else if (this.header_action && this.header_action?.action){
        this.$store.dispatch('layout/setBackLinkTriggerValue')
      } else if (this.backToName){
        this.$router.replace({ name: this.backToName });
      } else {
        this.$router.replace(
          (this.get_menu_item_by_id(this.menu_id) && 
          this.get_menu_item_by_id(this.menu_id)['parent'] && 
          this.get_menu_item_by_id(this.menu_id)['parent'] >= 0) ? {
            name: 'NavigationItemChild',
            params: {
              menu_id: this.get_menu_item_by_id(this.menu_id)['parent']
            }
          } : {
            name: 'MainPage',
          }
        );
      }
    },

  },
}
</script>