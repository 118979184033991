import { mapGetters, mapState } from "vuex";
import { backNavigateQueue } from '@/helpers/app';

export default {
  props: ['menu_id', 'backToName', 'backToPath'],
  computed: {
    ...mapState('layout', [
      'header_action', 
      'rotated_chart_component'
    ]),
    ...mapGetters('mainPage', [
      'get_menu_item_by_id',
    ])
  },
  
  methods: {
    handleBackClick(){
      console.log('handleBackClick')
      if (this.rotated_chart_component){
        this.$store.dispatch('layout/clearRotatedChartComponent')
      } else if (this.header_action && this.header_action?.action){
        this.$store.dispatch('layout/setBackLinkTriggerValue')
      } else if (this.backToName){
        this.$router.replace({ name: this.backToName });
      } else if (this.backToPath){
        this.$router.replace({ path: this.backToPath });
      } else {
        this.$router.replace(
          (this.get_menu_item_by_id(this.menu_id) && 
          this.get_menu_item_by_id(this.menu_id)['parent'] && 
          this.get_menu_item_by_id(this.menu_id)['parent'] >= 0) ? {
            name: 'NavigationItemChild',
            params: {
              menu_id: this.get_menu_item_by_id(this.menu_id)['parent']
            }
          } : {
            name: 'MainPage',
          }
        )
      }
    }
  },
  mounted(){
    backNavigateQueue.set(this.handleBackClick);
  }
}