<template>
  <div class="FORMS__upload">
    <input 
      ref="fileInput" 
      type="file" 
      multiple 
      style="display:none"
      @change="handleFileInputChange"
    >
    <div
      class="upload__files"
      v-if="files && files.length"
    >
      <div 
        v-for="(file, k) in files" 
        class="upload__files__tile"
        :key="k"
      >
        <div class="files__tile__content">
          <img 
            v-if="isImage(file)"
            :src="createObjUrl(file)" 
            alt=""
            width="64"
            height="64"
          >
          <p 
            v-else 
            class=""
          >{{ file.name }}</p>
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isLoading" v-bind:class="'delete-btn'" v-bind:svg-inline="''" @click="deleteFileByIndex(k)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M12 21a9 9 0 100-18 9 9 0 000 18z" fill="#F6F6F6" stroke="#1C1D21" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/><path d="M15 9l-6 6M15 15L9 9" stroke="#1C1D21" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
    </div>
    <div
      v-if="!isLoading"
      class="upload__upload-btn"
      @click="handleUploadBtnClick"
    >
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:class="'mr-8'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path clip-rule="evenodd" d="M9.824 1.841H5.39c-1.373 0-2.556 1.113-2.556 2.486v7.233c0 1.45 1.105 2.517 2.555 2.517h5.326c1.373 0 2.486-1.144 2.486-2.517V5.359L9.825 1.84z" stroke="#151C22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9.65 1.833v1.94c0 .946.765 1.714 1.712 1.716.877.003 1.775.003 1.836 0M9.53 8.61H6.262M7.896 10.244V6.976" stroke="#151C22" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
      <p class="small">{{ $translate('form-upload-file_title') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UploadFileForm',
  props: ['component', 'error_msg', 'isLoading'],
  data(){
    return {
      files: [],
    }
  },
  watch: {
    files: {
      immediate: true,
      handler(newValue){
        this.$store.dispatch('form/setFieldValue', {
          field: this.component.id, 
          value: newValue
        });
      },
    }
  },
  methods: {
    
    handleUploadBtnClick(){
      if (this.files.length < 5){
        this.$refs.fileInput.click();
      } else {
        this.$store.dispatch('layout/alert', this.$translate('form-upload-file_upload-max-count-alert'));
      }
    },

    // Adds `File` object to `files` data obj
    handleFileInputChange(event){
      if (this.files.length + event?.target?.files?.length > 5){
        this.$store.dispatch('layout/alert', this.$translate('form-upload-file_add-max-count-alert'));
      }
      this.files = [
        ...this.files,
        ...event.target.files
      ].slice(0,5)
    },

    // creates
    createObjUrl(file){
      return URL.createObjectURL(file);
    },

    isImage(file){
      return file && file['type'].split('/')[0] === 'image';
    },

    deleteFileByIndex(index){
      this.$delete(this.files, index)
    }
  },
}
</script>

<style lang="scss">
.FORMS__upload{
  margin: 8px 16px 0;
  .upload__files{
    display: flex;
    flex-wrap: wrap;
    .upload__files__tile{
      position: relative;
      padding: 8px;
      margin: 0 12px 12px 0;
      border: 1px solid #F9F9F9;
      border-radius: 12px;
      .files__tile__content{
        width: 64px;
        height: 64px;
        overflow: hidden;
        >img{
          height: 100%;
          width: 100%;
          object-fit: contain;
        }
      }
      >.delete-btn{
        position: absolute;
        right: -8px;
        top: -8px;
      }
    } 
  }
  .upload__upload-btn{
    margin: 4px 0 0 0;
    display: inline-flex;
    align-items: center;
    padding: 8px 16px;
    border: 1px solid #F9F9F9;
    border-radius: 12px;
  }
}
</style>