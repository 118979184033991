<template>
  <div class="FORMS__tiles">
    <div 
      v-for="(item, k) in tiles"
      class="tiles__item-container"
      :key="k+item.id"
    >
      <div 
        class="tiles__item"
        :style="{
          backgroundColor: item.color || '#8897A5',
          border: '1px solid ' + (item.color || '#8897A5'), 
          borderColor: item.border_color,
        }"
        v-ripple="!!item.form_action"
        @click="item.form_action && !isLoading && $emit('formAction', item.form_action)"
      >
        <div class="tiles__item__title-container">
          <p 
            :class="{
              [item.title_text_size || 'small']: true,
            }"
            :style="{
              color: item.text_color,
            }"
          >{{ item.title }}</p>
          <div 
            v-if="item.dot_color"
            class="tiles__item__dot"
            :style="{ backgroundColor: item.dot_color }"
          ></div>
        </div>
        <p 
          :class="{
            [item.value_text_size || 'h5']: true,
          }"
          :style="{
            color: item.text_color,
          }"
        >{{ item.value }}</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TilesForm',
  props: ['component', 'isLoading'],
  data(){
    return {
      tiles: this.component.content,
    }
  },
}
</script>
<style lang="scss">
.FORMS__tiles{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 13px; // 16 - 3 px
  .tiles__item-container{
    flex: 1 0 33.3%;
    max-width: 33.3%;
    margin: 0 0 8px 0;
    .tiles__item{
      padding: 12px;
      margin: 0 3px;
      border-radius: 12px;
      color: #FFF;
      height: 100%;
      &__title-container {
        margin: 0 0 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
          min-width: 0;
          word-break: break-word;
        }
      }
      &__dot {
        margin: 0 0 0 4px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #FFF;
        flex: 0 0 auto;
        align-self: baseline;
      }
    } 
    &:first-child{
      max-width: 50%;
    }
    &:nth-child(2){
      max-width: 50%;
    }
  }
}
</style>