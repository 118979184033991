<template>
  <div 
    class="favourite-toggler-row"
    :class="{
      'favourite-toggler-row--spaced': spaced
    }"
  >
    <div class="favourite-toggler-row__title">
      <span class="favourite-toggler-row__title__text">
        {{ $translate('favourite-toggler_title') }}
      </span>
    </div>
    <div 
      class="favourite-toggler-row__toggler"
      :class="{
        'favourite-toggler-row__toggler--active': is_favourite(menu_id),
        'favourite-toggler-row__toggler--disabled': add_favourite_req_sent,
      }"
      @click="handleTogglerClick"
    >
      <div class="favourite-toggler-row__toggler__circle-container">
        <div class="favourite-toggler-row__toggler__circle"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "FavouriteTogglerRow",
  props: [
    'menu_id',
    'spaced',
  ],
  data(){
    return {
      add_favourite_req_sent: false,
    }
  },
  computed: {
    ...mapGetters("mainPage", [ 
      'is_favourite', 
    ]),
  },

  methods: {
    
    handleTogglerClick(){
      if (this.add_favourite_req_sent === false){
        this.add_favourite_req_sent = true;
        let action = this.is_favourite(this.menu_id)
          ? "mainPage/removeFavourite" 
          : "mainPage/addFavourite"
        this.$store.dispatch(action, this.menu_id)
          .finally(() => {
            this.add_favourite_req_sent = false;
          })
      }
    },

  },

}
</script>

<style lang="scss">
.favourite-toggler-row{
  display: flex;
  align-items: center;
  &--spaced{
    padding: 12px 16px;
  }
  &__title{
    flex-grow: 1;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    overflow: hidden;
    margin: 0 12px 0 0;
    // &__img{
    //   margin: 0 8px 0 0;
    //   height: 24px;
    //   width: 24px;
    // }
    &__text{
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
  &__toggler{
    background: #EEF0F2;
    border-radius: 12px;
    // height: 20px;
    padding: 2px;
    width: 36px;
    // position: relative;
    &__circle-container{
      width: 100%;
      margin-left: auto;
      margin-right: 0;
      transition: width 0.1s ease-in;
    }
    &__circle{
      background: #FFFFFF;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
      // transition-property: left, right;
      // transition-duration: 3s, 5s;
      
    }
    &--active {
      background: #EF7F1A;
      .favourite-toggler-row__toggler__circle-container{
        width: 16px;   
      }
    }
    &--disabled{
      background: #EEF0F2;
    }
    
  }
}
</style>