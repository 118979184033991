<template>
<div class="simple-catalog-form">
  <div 
    v-for="(item, k) in items"
    :key="item.id + '_' + k"
    class="simple-catalog-form__item"
    :style="{
      backgroundColor: itemBgColor(item),
    }"
    @click="item.form_action && !isLoading && $emit('formAction', item.form_action )"
  >
    <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="itemHasPersonIcon(item)" v-bind:class="'item__left-svg'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M8.247 13.175c-4.313 0-7.997.68-7.997 3.4C.25 19.295 3.91 20 8.247 20c4.313 0 7.997-.68 7.997-3.4 0-2.721-3.66-3.425-7.997-3.425z" fill="#8897A5"/><path opacity=".4" d="M8.247 10.584a5.273 5.273 0 005.292-5.292A5.273 5.273 0 008.247 0a5.274 5.274 0 00-5.292 5.292 5.274 5.274 0 005.292 5.292z" fill="#8897A5"/></svg>
    <div
      v-else-if="itemImage(item)"
      class="item__img-icon"
    >
      <img 
        height="24"
        width="24"
        :src="itemImage(item)" 
      >
    </div>
    <div class="item__text-container">
      <p 
        :style="{ 
          color: getItemTitleColor(item, isLoading), 
        }"
        class="item__text-container__title"
        :class="{
          'item__text-container__title--mutiline': multiline_title,
          [getItemTitleTextSize(item) || 'small-medium']: true,
        }
        "
      >
        <span 
          v-if="item.dot_color"
          class="item__text-container__title__dot"
          :style="{ backgroundColor: item.dot_color }"
        ></span>
        {{ item.title }}
      </p>
      <p 
        v-if="item.subtitle"
        :style="{ 
          color: getItemSubtitleColor(item, isLoading), 
        }"
        class="item__text-container__subtitle"
        :class="{
          'item__text-container__subtitle--mutiline': multiline_subtitle,
          [getItemSubtitleTextSize(item) || 'caption']: true
        }"
      >
        {{ item.subtitle || '' }}
      </p>
    </div>
    <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="(item.right_icon === 'arrow') || rightArrow" v-bind:class="'item__right-svg'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-else-if="item.right_icon === 'calendar'" v-bind:class="'item__right-svg'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.093 9.404h17.824M16.442 13.31h.01M12.005 13.31h.009M7.558 13.31h.01M16.442 17.196h.01M12.005 17.196h.009M7.558 17.196h.01M16.044 2v3.29M7.965 2v3.29" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path clip-rule="evenodd" d="M16.238 3.58H7.771C4.834 3.58 3 5.214 3 8.221v9.05C3 20.326 4.834 22 7.771 22h8.458C19.175 22 21 20.355 21 17.348V8.222c.01-3.007-1.816-4.643-4.762-4.643z" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </div>

</div>
</template>

<script>
export default {
  name: 'SimpleCatalogForm',
  props: ["component", "isLoading"],
  data(){
    return {
      items: this.component.items,
      rightArrow: this.component?.options?.item_right_icon_resource,
      multiline_subtitle: this.component?.options?.multiline_subtitle || false,
      multiline_title: this.component?.options?.multiline_title || false,
    }
  },
  methods: {
    getItemTitleColor: (item, isLoading) => (
      isLoading && '#dde1e5' || item?.options?.text_color
    ),
    getItemSubtitleColor: (item, isLoading) => (
      isLoading && '#dde1e5' || item?.options?.subtitle_text_color
    ),
    getItemTitleTextSize: (item) => (item?.options?.title_text_size),
    getItemSubtitleTextSize: (item) => (item?.options?.subtitle_text_size),
    itemHasPersonIcon: (item) => (!!item?.options?.contact_icon),
    itemImage: (item) => (item?.options?.image_src),
    itemBgColor: (item) => (item?.options?.background_color),
  },
}
</script>
<style lang="scss">
.simple-catalog-form{
  margin: 8px 0 0;
  border-radius: 12px;
  overflow: hidden;
  .simple-catalog-form__item{
    display: flex;
    align-items: center;
    margin: 0 0 1px;
    padding: 14px 16px;
    background: #FFFFFF;
    .item__text-container{
      flex: 1 1 auto;
      min-width: 0;
      margin-right: 4px;
      &__title{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &__dot{
          display: inline-block;
          width: 8px;
          height: 8px;
          margin: auto 6px auto auto;
          background: #EF7F1A;
          border-radius: 50px;
        }
        &--mutiline{
          white-space: normal;
        }
      }
      &__subtitle{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 4px;
        color: #667A8B;
        &--mutiline{
          white-space: normal;
        }
      }
    }
    .item__img-icon{
      flex: 0 0 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      margin-right: 16px;
      img{
        max-width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    .item__left-svg{
      margin-left: 4px;
      margin-right: 20px;
    }
    .item__right-svg{
      margin-left: auto;
      margin-right: 8px;
    }
  }
}
</style>