var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FORMS__tiles"},_vm._l((_vm.tiles),function(item,k){
var _obj, _obj$1;
return _c('div',{key:k+item.id,staticClass:"tiles__item-container"},[_c('div',{directives:[{name:"ripple",rawName:"v-ripple",value:(!!item.form_action),expression:"!!item.form_action"}],staticClass:"tiles__item",style:({
        backgroundColor: item.color || '#8897A5',
        border: '1px solid ' + (item.color || '#8897A5'), 
        borderColor: item.border_color,
      }),on:{"click":function($event){item.form_action && !_vm.isLoading && _vm.$emit('formAction', item.form_action)}}},[_c('div',{staticClass:"tiles__item__title-container"},[_c('p',{class:( _obj = {}, _obj[item.title_text_size || 'small'] = true, _obj ),style:({
            color: item.text_color,
          })},[_vm._v(_vm._s(item.title))]),(item.dot_color)?_c('div',{staticClass:"tiles__item__dot",style:({ backgroundColor: item.dot_color })}):_vm._e()]),_c('p',{class:( _obj$1 = {}, _obj$1[item.value_text_size || 'h5'] = true, _obj$1 ),style:({
          color: item.text_color,
        })},[_vm._v(_vm._s(item.value))])])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }