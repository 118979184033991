<template>
  <div class="mx-16">
    <a 
      v-if="action === 'open_file'"
      v-bind="attrs"
      v-ripple
      :href="downloadUrl"
    >
      {{ title }}
    </a>
    <div 
      v-else-if="action === 'share_link'"
      v-bind="attrs"
      v-ripple
      @click="handleShareClick"
    >
      <v-progress-circular
        v-if="shareFileDownloading"
        indeterminate
        color="#FFF"
      ></v-progress-circular>
      <template v-else-if="downloadError">
        {{ downloadError }}
      </template>
      <template v-else>
        {{ title }}
      </template>
    </div>
    <a 
      v-else-if="
        action === 'open_url'
        && isPhoneTel(openUrlHref)
      "
      v-bind="attrs"
      v-ripple
      :href="openUrlHref"
    >
      <v-progress-circular
        v-if="linkLoading"
        indeterminate
        color="#FFF"
      ></v-progress-circular>
      <template v-else>
        {{ title }} 
      </template>
    </a>
    <div
      v-else
      v-bind="attrs"
      v-ripple
      @click="handleActionClick"
    >
      {{ title }}
    </div>
  </div>
</template>

<script>
import baseApi from "@/helpers/baseApi";
import bridge from "@/helpers/bridge";
import { handleExternalNavigation } from "@/helpers/utils"
export default {
  name: 'SubmitForm',
  props: ['component', 'isLoading'],
  data(){
    return {
      action: this.component?.form_action?.action,
      bgColor: this.component?.options?.background_color,
      borderColor: this.component?.options?.border_color,
      downloadError: undefined,
      shareDataUrl: undefined,
      downloadUrl: this.component?.form_action?.url 
        && ((process.env.VUE_APP_API_HOST || '') + this.component?.form_action?.url),
      formAction: this.component?.form_action,
      linkLoading: false,
      openUrlHref: this.component?.form_action?.data_template,
      shareFileName: undefined,
      shareFileDownloading: true,
      textColor: this.component?.options?.text_color,
      title: this.component.title,
      width: this.component?.options?.width,
    }
  },

  computed: {
    attrs(){
      return {
        class: this.class,
        style: {
          background: this.bgColor || undefined,
          color: this.textColor || undefined,
          border: this.borderColor ? `1px solid ${this.borderColor}` : undefined,
          width: this.width ? `${this.width}%` : undefined,
        }
      }
    },

    class(){
      let disabled = (this.action === 'share_link' && this.shareFileDownloading)
        || (this.action === "submit_form" && this.isLoading)
        || (this.action === "open_url" && this.linkLoading)
        || undefined;
      return {
        "submit-form": true,
        'submit-form--disabled': disabled,
        'submit-form--error': this.action === 'share_link' && this.downloadError,
      };
    },

  },

  mounted(){
    if (this.action === 'share_link'){
      this.downloadByURL(this.downloadUrl, this.component?.options?.method);
    }
    if (this.action === 'open_url'){
      // Android
      document.addEventListener("visibilitychange", this.disableLinkSpinner);
      //iOS
      window.addEventListener("pageshow", this.disableIOSLinkSpinner);
    }
  },

  beforeDestroy(){
    URL.revokeObjectURL(this.shareDataUrl);
    document.removeEventListener("visibilitychange", this.disableLinkSpinner);
    window.removeEventListener("pageshow", this.disableIOSLinkSpinner);
  },

  methods: {
    
    disableIOSLinkSpinner(event){
      if (event.persisted) { // check if page is loading from bfcache
        this.linkLoading = false;
        console.log(this);
      }
    },

    isPhoneTel(url){
      var searchPattern = new RegExp('^tel:');
      return searchPattern.test(url);
    },

    disableLinkSpinner(){
      this.linkLoading = false;
      console.log(this);
    },

    handleActionClick(){
      if (this.linkLoading) return 
      if (this.action === 'open_url'){
        this.linkLoading = true;
        try {
          handleExternalNavigation(this.openUrlHref, {
            cookie: this.component?.form_action?.cookie,
          })
        } catch {
          this.linkLoading = false;
          this.$store.dispatch(
            'layout/alert', 
            this.$translate('form-submit_external-nav-error')
          );
        } finally {
          this.linkLoading = true;
        }
      } else {
        this.$emit('formAction', this.formAction );
      }
    },

    async downloadByURL(url, method = 'get'){
      try{
        let res = await baseApi(url, { 
          method, 
          responseType: 'blob',
          // headers: {
          //   "Access-Control-Expose-Headers": "Content-Disposition"
          // } 
        });
        let disposition = res.headers['content-disposition'];
        console.log('disp', disposition);
        let trimmedUrl = url.replace(/\/+$/, '');
        let fileTitle = trimmedUrl.split('/')[trimmedUrl.split('/').length-1];
        let fileFormat = res?.headers?.['content-type'].split('/')[res.headers['content-type'].split('/').length-1];
        let reader = new window.FileReader();

        reader.readAsDataURL(res.data);
        reader.onload = function() {
          let imageDataUrl = reader.result;
          this.shareDataUrl = imageDataUrl.replace(/^data:(.*,)?/, "");
          this.shareFileName = disposition 
            ? decodeURI(disposition.split("filename=")[disposition.split("filename=").length -1]) // Not implemented on backend side
            : fileTitle && fileFormat 
            ? `${fileTitle}.${fileFormat}` 
            : undefined;
          this.shareFileDownloading = false;
        }.bind(this);
        reader.onerror = function(err){
          this.$store.dispatch('layout/alert', err.message);
          this.downloadError = this.$translate('form-submit_file-not-found');
          this.shareFileDownloading = false;
        }.bind(this);
      } catch(err){
        console.log(Object.keys(err));
        this.$store.dispatch('layout/alert', err.message);
        this.downloadError = this.$translate('form-submit_file-download-error');
        this.shareFileDownloading = false;
      }
    },

    async handleShareClick(){
      if (this.shareFileDownloading){
        this.$store.dispatch('layout/alert', this.$translate('form-submit_share-loading'));
      } else if (!this.shareDataUrl) {
        this.$store.dispatch('layout/alert', this.$translate('form-submit_share-error'))
      } else {
        try {
          let status = await bridge.shareFile(
            '', 
            this.shareFileName || 'файл', 
            this.shareDataUrl
          );
          if (status === 'failed'){
            this.$store.dispatch('layout/alert', this.$translate('form-submit_bridge-share-error'))
          }
        } catch(err) {
          this.$store.dispatch('layout/alert', err)
        }
      }
    }
  }
}
</script>
<style lang="scss">
.submit-form{
  display: block;
  background: #EF7F1A;
  color: white;
  border-radius: 16px;
  padding: 14px 16px;
  margin: 24px auto;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  user-select: none;
}
.submit-form--disabled{
  background: #DDE1E5 !important;
  border: none !important;
  color: #999999;
}
.submit-form--error{
  background: #FFF !important;
  border: 1px solid #F5463B !important;
  color: #F5463B !important;
}
</style>