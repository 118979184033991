var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"FORMS__text-container",style:({
    justifyContent: _vm.isTextCenter && 'center' || undefined,
  })},[(_vm.shareFileDownloading)?_c('v-progress-circular',{staticClass:"mr-8",attrs:{"indeterminate":"","color":"#999","size":"16","width":"2"}}):(_vm.iconLeft)?_c('div',{staticClass:"text-container__icon-left",style:({
      color: _vm.iconLeftColor
    })},[_c('FormIcon',{attrs:{"icon":_vm.iconLeft}})],1):_vm._e(),_c(_vm.action === 'open_file' ? 'a' : 'p',{tag:"component",staticClass:"text-container__text",class:[ _vm.textSize ],style:({
      color: _vm.shareFileDownloading ? '#999' : this.color,
      textAlign: _vm.isTextCenter ? 'center' : undefined,
      flexGrow: _vm.isTextCenter ? '0' : undefined,
      userSelect: _vm.isNoCopy ? 'none' : undefined,
    }),attrs:{"href":_vm.downloadUrl},domProps:{"innerHTML":_vm._s(_vm.shareDownloadError || _vm.title)},on:{"click":_vm.handleClick}}),(_vm.iconRight)?_c('div',{style:({
      marginLeft: '16px',
      color: _vm.iconRightColor
    })},[_c('FormIcon',{attrs:{"icon":_vm.iconRight}})],1):((_vm.action === 'open_url'))?_c('div',{staticClass:"text-container__copy-action",on:{"click":function($event){return _vm.handleCopy(_vm.href)}}},[_c('FormIcon',{attrs:{"icon":"copy"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }