<template>
  <div 
    class="FORMS__text-container"
    :style="{
      justifyContent: isTextCenter && 'center' || undefined,
    }"
  >
    <v-progress-circular
      v-if="shareFileDownloading"
      indeterminate
      color="#999"
      class="mr-8"
      size="16"
      width="2"
    ></v-progress-circular>
    <div 
      v-else-if="iconLeft"
      class="text-container__icon-left"
      :style="{
        color: iconLeftColor
      }"
    >
      <FormIcon 
        :icon="iconLeft"
      />
    </div>
    <component
      :is="action === 'open_file' ? 'a' : 'p'"
      :href="downloadUrl"
      class="text-container__text"
      :class="[ textSize ]"
      :style="{
        color: shareFileDownloading ? '#999' : this.color,
        textAlign: isTextCenter ? 'center' : undefined,
        flexGrow: isTextCenter ? '0' : undefined,
        userSelect: isNoCopy ? 'none' : undefined,
      }"
      @click="handleClick"
      v-html="shareDownloadError || title"
    >
    </component>
    <div 
      v-if="iconRight"
      :style="{
        marginLeft: '16px',
        color: iconRightColor
      }"
    >
      <FormIcon 
        :icon="iconRight"
      />
    </div>
    <div 
      v-else-if="(action === 'open_url')"
      class="text-container__copy-action"
      @click="handleCopy(href)"
    >
      <FormIcon
        icon="copy"
      />
    </div>
  </div>
</template>

<script>
import bridge from "@/helpers/bridge";
import isMobile from "ismobilejs";
import FormIcon from '@/components/Forms/partials/FormIcon';
import baseApi from "@/helpers/baseApi";
import { handleExternalNavigation } from '@/helpers/utils'
export default {
  name: 'TextForm',
  props: ["component"],
  components: {
    FormIcon
  },
  data(){
    return {
      title: this.component?.title,
      color: this.component?.options?.text_color,
      textSize: this.component?.options?.text_size,
      iconLeft: this.component?.options?.item_left_icon_resource || undefined,
      iconLeftColor: this.component?.options?.item_left_icon_color || '#667A8B',
      iconRight: this.component?.options?.item_right_icon_resource || undefined,
      iconRightColor: this.component?.options?.item_right_icon_color || '#667A8B',
      isNoCopy: this.component?.options?.no_copy_text,
      isTextCenter: this.component?.options?.alignment === 'center_horizontal',
      action: this.component?.form_action?.action,
      href: this.component?.form_action?.data_template,
      shareDataUrl: undefined,
      shareDownloadError: '',
      shareFileDownloading: false,
      shareFileName: undefined,
      downloadUrl: this.component?.form_action?.url 
        && ((process.env.VUE_APP_API_HOST || '') + this.component?.form_action?.url),
    }
  },
  
  mounted(){
    if (this.action === 'share_link'){
      this.downloadByURL(this.downloadUrl, this.component?.options?.method);
    }
  },

  beforeDestroy(){
    URL.revokeObjectURL(this.shareDataUrl);
  },

  methods: {
    handleClick(e){
      if(
        !this.action 
        || this.action === 'open_file'
      ){
        return
      } else if(this.action === 'open_url'){
        handleExternalNavigation(this.href, {
          cookie: this.component?.form_action?.cookie,
        });
      } else if (this.action === 'share_link'){
        this.handleShareClick();
      } else {
        this.$emit('formAction', this.component.form_action );
        e.preventDefault();
      }
    },

    async downloadByURL(url, method = 'get'){
      try{
        this.shareFileDownloading = true;
        let res = await baseApi(url, { 
          method, 
          responseType: 'blob' 
        });
        let disposition = res.headers['content-disposition'];
        console.log('res.headers', res.headers);
        console.log('disposition', disposition);
        let reader = new window.FileReader();
        let trimmedUrl = url.replace(/\/+$/, '');
        let fileTitle = trimmedUrl.split('/')[trimmedUrl.split('/').length-1]
        let fileFormat = res?.headers?.['content-type'].split('/')[res.headers['content-type'].split('/').length-1]
        this.shareFileName = disposition 
          ? decodeURI(disposition.split("filename=")[disposition.split("filename=").length -1]) // Not implemented on backend side
          : fileTitle && fileFormat 
          ? `${fileTitle}.${fileFormat}` 
          : undefined;
        reader.readAsDataURL(res.data);
        reader.onload = function() {
          var imageDataUrl = reader.result;
          this.shareDataUrl = imageDataUrl.replace(/^data:(.*,)?/, "");
          this.shareFileDownloading = false;
        }.bind(this);
        reader.onerror = function(err){
          this.$store.dispatch('layout/alert', err.message);
          this.shareDownloadError = this.$translate('form-text_file-not-found');
          this.shareFileDownloading = false;
        }.bind(this);
      } catch(err){
        console.log(Object.keys(err));
        this.$store.dispatch('layout/alert', err.message);
        this.shareDownloadError = this.$translate('form-text_file-download-error');
        this.shareFileDownloading = false;
      }
    },

    async handleShareClick(){
      if (this.shareFileDownloading){
        this.$store.dispatch('layout/alert', this.$translate('form-text_share-loading'))
      } else if (!this.shareDataUrl) {
        this.$store.dispatch('layout/alert', this.$translate('form-text_share-error'))
      } else {
        try {
          let status = await bridge.shareFile(
            '', 
            this.shareFileName || 'файл', 
            this.shareDataUrl
          );
          if (status === 'failed'){
            this.$store.dispatch('layout/alert', this.$translate('form-text_bridge-share-error'))
          }
        } catch(err) {
          this.$store.dispatch('layout/alert', err)
        }
      }
    },

    async handleCopy(value){
      let isAndroid = isMobile(window.navigator).android.device;
      try {
        let result = await bridge.copyToClipboard(value);
        if (result === "success"){
          if (!isAndroid){
            this.$store.dispatch('layout/alert', this.$translate('form-text_copy-success'));
          }
        } else {
          this.$store.dispatch('layout/alert', this.$translate('form-text_copy-error'));
        }
      } catch(err) {
        this.$store.dispatch('layout/alert', err);
      }
    }
  }

}
</script>
<style lang="scss">
.FORMS__text-container{
  display: flex;
  min-width: 0;
  margin: 0 16px 8px;
  .text-container__icon-left{
    margin-right: 16px;
  }
  .text-container__text{
    flex: 1 1 auto;
    word-break: break-word;
    min-width: 0;
    white-space: pre-wrap;
    ul {
      padding-left: 1rem;
    }
    ol {
      padding-left: 1rem;
    }
  }
  .text-container__copy-action{
    display:flex;
    align-items: center;
    color: #0086E7;
    border-left: 1px solid #DDE1E5;
    margin-left: 16px;
    padding-left: 16px;
  }
}
</style>