<template>
  <div class="mx-16">
    <p>Не найден компонент: {{ component.type }}</p>
  </div>
</template>

<script>
export default {
  name: 'NotFoundForm',
  props: ['component'],
}
</script>

<style>

</style>