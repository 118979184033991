<template>
  <div class="search-form">
    <div 
      class="search-form__input-div"
      :class="{
        'search-form__input-div--focused': focused
      }"
      @focus.capture="focused = true"
      @blur.capture="focused = false"
    >
      <input 
        ref="searchInput"
        :value="searchValue"
        @input="
          $event.target.composing = false;
          $emit('input', $event.target.value);
          searchValue = $event.target.value;
        "
        type="text"
        class="search-form__input-div__search-input"
        :placeholder="focused ? placeholder : undefined"
        @focus="$store.dispatch('layout/hideFooter')"
        @blur="$store.dispatch('layout/showFooter')"
      >
      <div 
        class="search-form__input-div__search-icon-container"
        :class="(focused || searchValue) ? 'search-form__input-div__search-icon-container--focused' : undefined"
        @click="$refs.searchInput.focus()"
      >
        <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'search-form__input-div__search-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.827 11.467a6.11 6.11 0 1112.22 0 6.11 6.11 0 01-12.22 0zm6.11-8.11a8.11 8.11 0 105.76 13.82 1 1 0 00.161.211l2.608 2.608a1 1 0 001.414-1.414l-2.608-2.608a.995.995 0 00-.428-.254 8.11 8.11 0 00-6.906-12.363z"/></svg> 
        <span 
          v-if="!(focused || searchValue)"
          class="ml-4"
        >{{ placeholder }}</span>
      </div>
      <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="searchValue" @click.prevent="searchValue = ''" v-bind:svg-inline="''" v-bind:class="'search-form__input-div__close-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>  
    </div>
    <div class="search-form__result">
      <template v-if="searchValue && result_content">
        <component 
          v-for="(component, k) in result_content"
          :component="component"
          :is="componentMap[component.type] || componentMap['not_found']"
          :key="content_key+'_'+component.type+'_'+k"
          @formAction="(options) => $emit('formAction', options)"
        />
      </template>
      <div 
        v-else-if="loading"
        class="search-form__result__loader-container"
      >
        <v-progress-circular
          indeterminate
          :color="theme_color"
        />
      </div>
      <template v-else-if="!searchValue && empty_content">
        <component 
          v-for="(component, k) in empty_content"
          :component="component"
          :is="componentMap[component.type] || componentMap['not_found']"
          :key="'empty-content_'+component.type+'_'+k"
          @formAction="(options) => $emit('formAction', options)"
        />
      </template>
    </div>
  </div>
</template>
<script>
import FORMS from "@/components/Forms/index.js";
import baseApi from "@/helpers/baseApi";
import axios from "axios";
import { mapState } from "vuex";

export default {
  name: 'SearchForm',
  props: ['component'],
  data(){
    return {
      loading: false,
      searchErrorText: undefined,
      focused: false,
      searchValue: '',
      searchTimeout: undefined,
      placeholder: this.component.placeholder || this.$translate('form-search_placeholder'),
      searchURL: this.component.url,
      empty_content: this.component?.options?.empty_content,
      result_content: undefined,
      content_key: '1',
      requestCancelToken: undefined,
      componentMap: {
        ...FORMS
      },
    }
  },
  watch: {
    searchValue(val){
      this.loading = false;
      this.searchErrorText = undefined;
      this.result_content = undefined;
      clearTimeout(this.searchTimeout);
      if (!val) return
      this.loading = true;
      this.searchTimeout = setTimeout(this.handleSearchRequest, 800);
    } 
  },
  computed: {
    ...mapState('layout', ['theme_color']),
  },
  methods: {
    async handleSearchRequest(){
      this.loading = true;
      if (typeof this.requestCancelToken != typeof undefined) {
        this.requestCancelToken.cancel("Operation canceled due to new request.")
      }
      this.requestCancelToken = axios.CancelToken.source()
      try {
        let { data } = await baseApi.get(
          this.searchURL + '?search=' + this.searchValue,
          { cancelToken: this.requestCancelToken.token }
        )
        this.result_content = data?.form?.content;
        this.content_key = this.searchValue;
        this.loading = false;
        console.log('search:', data);
      } catch (error) {
        if (!axios.isCancel(error)){
          this.loading = false;
          this.result_content = undefined;
          this.searchErrorText = error;
          console.error(error);
        }
      }
    },
  },  
}
</script>
<style lang="scss">
.search-form{
  &__input-div{
    position: relative;
    // width: 100%;
    color: #8897A5;
    margin: 0 16px;
    &--focused{
      color: #151C22;
    }
    &__search-input{
      width: 100%;
      padding: 12px 48px;
      background-color: #FFF;
      border: 1px solid #F9F9F9;
      border: none;
      border-radius: 6px;
    }
    &__search-icon-container{
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1;
      margin: auto;
      &__search-icon{
        fill: currentColor;
        pointer-events: none;
      }
      &--focused{
        right: auto;
        .search-form__input-div__search-icon{
          margin-left: 1rem;
        }
      }  

    }
    &__close-icon{
      // fill: black;
      // stroke: currentColor;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 1;
      margin: auto;
      margin-right: 1rem;
      // cursor: pointer;  
    }
  }
  &__result{
    width: 100%;
    &__loader-container{
      display: flex; 
      justify-content: center;
      margin-top: 16px;
    }
  }
}
.form__fullscreen{
  .search-form__input-div{
    &__search-input{
      background-color: #F6F6F6;
    }
  }
}
</style>