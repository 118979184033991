<template>
  <div class="error-placeholder">
    <div class="error-placeholder__text-container">
      <svg width="33" height="34" viewBox="0 0 33 34" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16.5 32c8.284 0 15-6.716 15-15 0-8.284-6.716-15-15-15-8.284 0-15 6.716-15 15 0 8.284 6.716 15 15 15z" fill="#EF7F1A" fill-opacity=".16"/><path d="M5.893 6.393l21.214 21.214M31.5 17c0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15 0-8.284 6.716-15 15-15 8.284 0 15 6.716 15 15z" stroke="#EF7F1A" stroke-width="2.5" stroke-linecap="round"/></svg>
      <p class="error-placeholder__text-container__title">
        {{ 
          statusTitle 
          || title 
          || $translate('error-placeholder_default-title')
        }}
      </p>
      <p class="small text-gray">
        {{ 
          statusContent 
          || (error && error.message) 
          || content 
          || $translate('error-placeholder_default-content')
        }}
      </p>
      <v-btn 
        v-if="$listeners['retry']"
        outlined
        :disabled="retryClicked || reloadClicked || retryLoading"
        @click="handleRetryClick"
        class="mt-16"
      >
        {{ $translate('error-placeholder_retry-btn-title')}}
        <v-progress-circular
          v-if="retryClicked || retryLoading"
          indeterminate 
          class="ml-12"
          size="14"
          width="2"
          color="#00000088"
        />
      </v-btn>
      <v-btn 
        v-if="showBackBtn"
        outlined
        :disabled="retryClicked || reloadClicked || retryLoading"
        @click="handleBackClick"
        class="mt-16"
      >
        {{ $translate('error-placeholder_back-to-main') }}
      </v-btn>
      <v-btn 
        v-if="isStatusReloadable"
        outlined
        :disabled="retryClicked || reloadClicked || retryLoading"
        @click="handleReloadClick"
        class="mt-16"
      >
        {{ $translate('error-placeholder_refresh') }}
        <img 
          class="ml-4"
          :class="{
            'error-placeholder__reload-icon--rotating': reloadClicked
          }"
          src="@/assets/svg/nav-menu_refresh.svg"
        >
      </v-btn>
    </div>
  </div>
</template>

<script>
const reloadableStatusesList = [401, 500, 504];
const backBtnStatusesList = [404];

export default {
  name: 'ErrorPlaceholder',
  props: {
    error: {
      type: Error,
    },
    content:{
      type: String
    },
    status: {
      type: Number,
      default: undefined,
    },
    title: {
      type: String,
    },
    retryLoading: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    showBackBtn(){
      let status = this.error?.response?.status;
      if (!status) return undefined;
      return backBtnStatusesList.includes(status)
    },
    statusTitle(){
      let status = this.error?.response?.status;
      if (!status) return undefined;
      return {
        401: this.$translate('error-placeholder_401-title'),
        404: this.$translate('error-placeholder_404-title'),
        500: this.$translate('error-placeholder_500-title'),
        504: this.$translate('error-placeholder_504-title'),
      }[status]
    },
    statusContent(){
      let status = this.error?.response?.status;
      if (!status) return undefined;
      return {
        401: this.$translate('error-placeholder_401-content'),
        404: this.$translate('error-placeholder_404-content'),
        500: this.$translate('error-placeholder_500-content'),
        504: this.$translate('error-placeholder_504-content'),
      }[status]
    },
    isStatusReloadable(){
      let status = this.error?.response?.status;
      return reloadableStatusesList.includes(status)
    }
  },
  data(){
    return {
      retryClicked: false,
      reloadClicked: false,
    }
  },
  methods: {

    async handleRetryClick(){
      this.retryClicked = true;
      await new Promise((resolve) => setTimeout(resolve, 800))
      this.$emit('retry');
      this.retryClicked = false;
    },

    async handleReloadClick(){
      this.reloadClicked = true;
      await new Promise((resolve) => setTimeout(resolve, 800));
      location.reload();
      this.retryClicked = false;
    },

    handleBackClick(){
      this.$router.replace({
        name: 'MainPage',
      })
    }
  }
}
</script>
<style lang="scss">
.error-placeholder{
  flex: 1 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  &__text-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 22px;
    &__title {
      font-size: 22px;
      font-weight: 600;
      line-height: 32px;
    }
  }
  &__reload-icon{
    &--rotating{
      animation: error-reload-rotating 2s linear infinite;
    }
  }
}
@keyframes error-reload-rotating {
  0% {
    transform: rotate(360deg);
  }
  25% {
    transform: rotate(270deg);  
  }
  50% {
    transform: rotate(180deg);  
  }
  75% {
    transform: rotate(90deg);  
  }
  100% {
    transform: rotate(0deg);
  }
}
</style>