import auth_submit from '@/components/Forms/AuthSubmitForm';
import chart_bar from '@/components/Forms/ChartBarForm';
import chart_line from '@/components/Forms/ChartLineForm';
import checkbox from "@/components/Forms/CheckboxForm"
import date_picker from "@/components/Forms/DatePickerForm"
import divider from "@/components/Forms/DividerForm"
import icon from "@/components/Forms/IconForm"
import image from "@/components/Forms/ImageForm"
import kpi_title from '@/components/Forms/KpiTitleForm';
import labeled_text from "@/components/Forms/LabeledTextForm"
import not_found from "@/components/Forms/NotFoundForm"
import pin_input from "@/components/Forms/PinInputForm"
import progress from "@/components/Forms/ProgressForm"
import push_checkbox from '@/components/Forms/PushCheckboxForm';
import radio_group from "@/components/Forms/RadioGroupForm"
import search from "@/components/Forms/SearchForm"
import search_select from "@/components/Forms/SearchSelectForm"
import simple_catalog from "@/components/Forms/SimpleCatalogForm"
import switch_form from "@/components/Forms/SwitchForm"
import submit from "@/components/Forms/SubmitForm"
import text from "@/components/Forms/TextForm"
import text_area from "@/components/Forms/TextAreaForm"
import text_input from "@/components/Forms/TextInputForm"
import tiles from '@/components/Forms/TilesForm';
import upload_file from '@/components/Forms/UploadFileForm'
import qr_code from '@/components/Forms/QrCodeForm'

const FORMS = {
  auth_submit,
  'bar': chart_bar,
  'card_title': kpi_title,
  checkbox,
  date_picker,
  divider,
  icon,
  'input': text_input,
  image,
  labeled_text,
  'line': chart_line,
  not_found,
  push_checkbox,
  progress,
  pin_input,
  'radiogroup': radio_group,
  search,
  'select': search_select, 
  simple_catalog,
  submit,
  'switch': switch_form,
  text,
  text_area,
  tiles,
  upload_file,
  qr_code
}

export default FORMS;