<template>
  <div class="divider-form"></div>
</template>

<script>
export default {
  name: 'DividerForm',
  inheritAttrs: false,
}
</script>
<style>
.divider-form{
  width: 100%;
  border-bottom: 1px solid #DDE1E5;
  margin: 8px 0;
}
</style>