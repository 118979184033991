<template>
  <div class="search-select-form">
    <div 
      @click="showOverlay = true"
      class="search-select-form__input"
    >
      <p class="mb-0">{{ title }}</p>
      <FormIcon :icon="'user-plus'" />
    </div>
    <div 
      v-if="value && value.length"
      class="search-select-form__added-items"
    >
      <div
        v-for="item in value"
        :key="item.id"
        class="search-select-form__added-items__item"
      >
        <div>
          <p class="mb-4">{{ item.title }}</p>
          <p class="caption-medium text-gray">{{ item.subtitle }}</p>
        </div>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" @click="switchItem(item)" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>
      </div>
    </div>
    <p v-if="error_msg" class="FORMS__error-msg">{{ error_msg }}</p>
    <div 
      v-if="showOverlay"
      class="search-select-form__search-overlay"
    >
      <div class="search-select-form__search-container">
        
        <div class="search-container__header">
          <div 
            class="header__back-btn"
            @click="showOverlay = false"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'back-btn__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M16 4l-8 8 8 8" stroke="#151C22" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg> 
          </div>
          <p class="header__title medium">{{ title }}</p>
        </div>
        
        <div 
          class="search-container__input-field"
          :class="{
            'search-container__input-field--focused': focused
          }"
          @focus.capture="focused = true"
          @blur.capture="focused = false"
          
        >
          <input 
            ref="searchInput"
            :value="searchValue"
            @input="
              $event.target.composing = false;
              $emit('input', $event.target.value);
              searchValue = $event.target.value;
            "
            type="text"
            class="input-field__search-input"
            :placeholder="focused ? placeholder : undefined"
            @focus="$store.dispatch('layout/hideFooter')"
            @blur="$store.dispatch('layout/showFooter')"
          >
          <div 
            class="input-field__search-icon-container"
            :class="(focused || searchValue) ? 'input-field__search-icon-container--focused' : undefined"
            @click="$refs.searchInput.focus()"
          >
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'input-field__search-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.827 11.467a6.11 6.11 0 1112.22 0 6.11 6.11 0 01-12.22 0zm6.11-8.11a8.11 8.11 0 105.76 13.82 1 1 0 00.161.211l2.608 2.608a1 1 0 001.414-1.414l-2.608-2.608a.995.995 0 00-.428-.254 8.11 8.11 0 00-6.906-12.363z"/></svg> 
            <span 
              v-if="!(focused || searchValue)"
              class="ml-4"
            >{{ placeholder }}</span>
          </div>
          <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="searchValue" @click.prevent="searchValue = ''" v-bind:svg-inline="''" v-bind:class="'input-field__close-icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path fill-rule="evenodd" clip-rule="evenodd" d="M6.293 20.294a1 1 0 001.414 1.414l6.294-6.294 6.295 6.296a1 1 0 001.415-1.414L15.415 14l6.293-6.293a1 1 0 10-1.414-1.414L14 12.586 7.71 6.295a1 1 0 00-1.414 1.414L12.586 14l-6.293 6.294z" fill="#151C22"/></svg>  
        </div>
        <div class="search-container__result">
          <template v-if="result_content && result_content.length">
            <div 
              v-for="item in result_content"
              :key="item._id"
              class="result__item"
              @click.capture="switchItem({ 
                title: item.fio,
                subtitle: item.title,
                id: item._id,
              })"
            >
              <div class="result__item__left">
                <p class="mb-4">{{ item.fio }}</p>
                <p class="caption-medium text-gray">{{ item.title }}</p>
              </div>
              <v-simple-checkbox
                :value="item._id && value.some(el => el.id === item._id)"
                :disabled="isLoading"
                color="#267CCC"
                :ripple="false"
                class="ml-auto"
              ></v-simple-checkbox>
            </div>
          </template>
          <div 
            v-else-if="loading"
            class="mt-16"
            style="display: flex; justify-content: center;"
          >
            <v-progress-circular
              indeterminate
              :color="theme_color"
            />
          </div>
          <div v-else-if="searchErrorText">
            {{ searchErrorText }}
          </div>
          <div v-else-if="searchValue.length > 0">
            <p>{{ $translate('form-search-select_not-found')}}</p> 
          </div>
        </div>
      
      </div>

    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import baseApi from "@/helpers/baseApi";
import axios from "axios";
import FormIcon from '@/components/Forms/partials/FormIcon';
import FORMS from "@/components/Forms/"

export default {
  name: 'SearchSelectForm',
  props: {
    component: {
      type: Object
    }, 
    error_msg: {
      type: String,
    }, 
    isLoading: {
      type: Boolean,
    },
    value: {
      type: Array,
      default(){
        return [];
      }
    }
  },
  components: {
    FormIcon
  },
  data(){
    return {
      showOverlay: false,
      // SEARCH
      loading: false,
      focused: false,
      searchValue: '',
      title: this.component.title || this.$translate('form-search-select_title'),
      placeholder: this.component.placeholder || this.$translate('form-search-select_placeholder'),
      searchURL: this.component.url,
      searchTimeout: undefined,
      searchErrorText: undefined,
      result_content: undefined,
      content_key: '1',
      requestCancelToken: undefined,
      componentMap: {
        ...FORMS
      },
    }
  },

  watch: {
    searchValue(){
      this.result_content = undefined;
      clearTimeout(this.searchTimeout);
      this.searchErrorText = undefined;
      this.loading = true;
      this.searchTimeout = setTimeout(this.handleSearchRequest, 800);
    } 
  },
  
  computed: {
    ...mapState('layout', ['theme_color']),
  },

  methods: {

    async handleSearchRequest(){
      this.searchErrorText = undefined;
      this.loading = true;
      if (typeof this.requestCancelToken != typeof undefined) {
        this.requestCancelToken.cancel("Operation canceled due to new request.")
      }
      this.requestCancelToken = axios.CancelToken.source()
      try {
        let { data } = await baseApi.get(
          this.searchURL + '?search=' + this.searchValue,
          { cancelToken: this.requestCancelToken.token }
        )
        this.result_content = JSON.parse(data)?.data;
        this.content_key = this.searchValue;
        this.loading = false;
        console.log('select_search:', data);
      } catch (error) {
        if (!axios.isCancel(error)){
          this.loading = false;
          this.result_content = undefined;
          console.error(error);
          this.searchErrorText = error?.response?.data?.detail || error.message || error;
        }
      }
    },

    switchItem(item){
      let { id } = item;
      if (this.value.some(el => el.id === id)){
        let newVal = this.value.filter((el) => el.id !== id);
        console.log('removing:', id, 'new value:',  newVal)
        this.$store.dispatch('form/setFieldValue', { 
          field: this.component.id, 
          value: newVal
        });
      } else {
        let newVal = [ ...this.value, item]
        console.log('adding:', id, 'new value:',  newVal)
        this.$store.dispatch('form/setFieldValue', { 
          field: this.component.id, 
          value: newVal
        });
      }
    },

  },
}
</script>
<style lang="scss">
.search-select-form{
  margin: 0 16px;
  &__input{
    display: flex;
    color: #8897A5;
    background-color: #FFF;
    border: 1px solid #F9F9F9;
    padding: 14px 16px;
    margin: 0 0 8px;
    svg{
      margin-left: auto;
    }
  }
  &__added-items{
    display: flex;
    flex-direction: column;
    background: #FFFFFF;
    border: 1px solid #DDE1E5;
    border-radius: 12px;
    &__item{
      display: flex;
      align-items: center;
      padding: 8px 16px;
      &:not(:last-child){
        border-bottom: 1px solid #DDE1E5;
      }
      >div{
        min-width: 0;
        p {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      svg{
        min-width: 0;
        margin-left: auto;
      }
    }
  }
  &__search-overlay{
    display: flex;
    justify-content: center;
    align-items: stretch;
    position: fixed;
    overflow-y: hidden;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(21, 28, 34, 0.4);
    z-index: 10;
    padding-top: 12px;
    .search-select-form__search-container{
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
      background-color: #FFF;
      border-radius: 24px 24px 0 0;
      padding: 24px 16px;
      min-width: 0;
      
      // HEADER START
      .search-container__header{
        position: relative;
        padding: 0 28px;
        margin: 0 0 16px 0;
        .header__back-btn{
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          z-index: 1;
          .back-btn__icon{
            pointer-events: none;
          }
        }
        .header__title{
          text-align: center;
        }
      }
      // HEADER END

      // INPUT FIELD START
      .search-container__input-field{
        position: relative;
        width: 100%;
        color: #8897A5;
        margin: 0 0 16px 0;
        &.search-container__input-field--focused{
          color: #151C22;
        }
        .input-field__search-input{
          width: 100%;
          padding: 12px 48px;
          background-color: #F6F6F6;
          border: none;
          border-radius: 12px;
        }
        .input-field__search-icon-container{
          display: flex;
          align-items: center;
          justify-content: center;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1;
          margin: auto;
          .input-field__search-icon{
            fill: currentColor;
            pointer-events: none;
          }
          &.input-field__search-icon-container--focused{
            right: auto;
            .input-field__search-icon{
              margin-left: 1rem;
            }
          }  
        }
        .input-field__close-icon{
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 1;
          margin: auto;
          margin-right: 1rem;
        }
      }
      // INPUT FIELD END
      // SEARCH RESULT START
      .search-container__result{
        flex-grow: 1;
        overflow-y: scroll;
        padding: 0 4px 0 0;
        .result__item{
          display: flex;
          align-items: center;
          padding: 8px 0;
          border-bottom: 1px solid #DDE1E5;
          .result__item__left{
            min-width: 0;
            margin-right: 4px;
            p{
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
}
</style>