<template>
  <div class="kpi-title-form">
    <div class="kpi-title-form__title">
      <p class="large-medium">{{ title }}</p>
    </div>
    <div class="kpi-title-form__kpis">
      <div 
        v-for="(kpi, k) in kpis"
        class="kpi-title-form__kpi-item"
        :key="k"
      >
        <span 
          class="caption"
          
        >{{ kpi.label }}</span>
        <span 
          class="h6 ml-4"
          :style="{
            color: kpi.options && kpi.options.text_color
          }"  
        >{{ kpi.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'KpiTitleForm', 
  props: ['component'],
  data(){
    return {
      title: this.component.title,
      kpis: this.component?.right_text,
      
    }
  }
}
</script>

<style lang="scss">
.kpi-title-form{
  display: flex;
  margin: 0 16px 24px;
  &__kpis{
    display: flex;
    margin-left: auto;
  }
  &__kpi-item{
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-left: 8px;
    min-width: 64px;
    .caption{
      color: #8897A5;
    }
  }
}
</style>